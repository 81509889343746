import * as React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import {
  ArrowDownward as IconDown,
  ArrowUpward as IconUp
} from "@material-ui/icons";

import { NotAvailable } from "../../components/MeasurementNA";
import { StyledProgress } from "../Resources";
import { theme } from "../../core/withThemeProvider";
import { Configuration } from "../../core/configuration/config";
import { growthPercent } from "../services/ResourceService";

export const ResourceVolumeHistoryContainer = styled(Grid)`
  && {
    max-width: 350px;
    min-width: 290px;
    padding: 20px 25px;
    min-height: 120px;
  }
`;
ResourceVolumeHistoryContainer.displayName = "ResourceVolumeHistoryContainer";

export const ResourceVolumeHistoryValueBlock = styled.span`
  font-size: 50px;
  font-weight: lighter;
  & > span {
    font-size: 20px;
    align-self: baseline;
    padding-left: 5px;
  }
  & > div {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 10px;
    color: ${theme.palette.secondary.main};
    text-align: right;
    padding-bottom: 6px;
  }
`;
ResourceVolumeHistoryValueBlock.displayName = "ResourceVolumeHistoryValueBlock";

export const ResourceVolumeHistoryDetailsBlock = styled.div`
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  & > div {
    color: ${theme.palette.secondary.main};
    padding-top: 5px;
    font-size: 10px;
  }
`;
ResourceVolumeHistoryDetailsBlock.displayName =
  "ResourceVolumeHistoryDetailsBlock";

export const ResourceVolumeDrop = styled(IconDown)`
  && {
    color: ${Configuration.colors.icon.danger};
    font-size: 12px;
  }
`;
ResourceVolumeDrop.displayName = "ResourceVolumeDrop";

export const ResourceVolumeRise = styled(IconUp)`
  && {
    color: ${Configuration.colors.icon.normal};
    font-size: 12px;
  }
`;
ResourceVolumeRise.displayName = "ResourceVolumeRise";

interface IResourceVolumeHistory {
  month: number;
  monthName: string;
  volume?: number;
  volumeUnit?: string;
  volumeLastYear?: number;
  volumeLastYearUnit?: string;
  loading: boolean;
}
export const ResourceVolumeHistory: React.FunctionComponent<
  IResourceVolumeHistory
> = props => {
  const {
    month,
    monthName,
    volume,
    volumeUnit,
    volumeLastYear,
    volumeLastYearUnit,
    loading
  } = props;
  const growth = growthPercent(volume, volumeLastYear);
  return (
    <ResourceVolumeHistoryContainer
      container={true}
      item={true}
      xs={true}
      direction="row"
      alignItems="center"
      justify="space-between"
    >
      {loading && <StyledProgress margin="22px" />}
      {!loading && (
        <ResourceVolumeHistoryValueBlock>
          <NotAvailable fontSize="30px" property={volume}>
            {volume}
            <span>{volumeUnit}</span>
          </NotAvailable>
          {growth ? (
            <div>
              {growth.rise && <ResourceVolumeRise />}
              {growth.drop && <ResourceVolumeDrop />} {growth.percent}% Last
              Year ({volumeLastYear}
              {volumeLastYearUnit})
            </div>
          ) : (
            <div>&nbsp;</div>
          )}
        </ResourceVolumeHistoryValueBlock>
      )}
      <ResourceVolumeHistoryDetailsBlock>
        Past {month} month <div>{monthName}</div>
      </ResourceVolumeHistoryDetailsBlock>
    </ResourceVolumeHistoryContainer>
  );
};
ResourceVolumeHistory.displayName = "ResourceVolumeHistory";
