// @ts-ignore
import { fitBounds } from "google-map-react/utils";

import { IDevice, IDeviceCollection } from "../../Devices/models/IDevice";
import {
  IGeolocation,
  ILocation,
  initialLocations
} from "../models/IGeolocation";
import { Configuration } from "../../core/configuration/config";
import { IInfluxTableQuery } from "../../Resources/models/IResource";

export const getDeviceLocation = (device: IDevice): ILocation => {
  const extractedLocations = extractDeviceLocation(device);
  const map: ILocation = {
    ...initialLocations
  };
  if (extractedLocations) {
    map.center = extractedLocations;
    map.locations = [extractedLocations];
    map.zoom = Configuration.geo.defaultZoom.oneDevice;
  }
  return map;
};

export const getDeviceCollectionLocation = (
  collection: IDeviceCollection,
  height: number,
  width: number
): ILocation => {
  const extractedLocations = extractCollectionLocations(collection);
  const map: ILocation = {
    ...initialLocations
  };
  if (extractedLocations) {
    map.locations = extractCollectionLocations(collection);
    const bounds = getBounds(map.locations, height, width);
    map.center = bounds.center;
    map.zoom = bounds.zoom;
  }
  return map;
};

export const generateDeviceDataPointString = (
  measurement?: IInfluxTableQuery
): string => {
  const { defaultDateFormat, defaultTimeFormat } = Configuration;
  let deviceMeasurement = "";
  if (!measurement) {
    return "";
  }
  if (measurement.primaryParameter && measurement.primaryParameter.value) {
    deviceMeasurement = measurement.primaryParameter.value.toString();
    if (measurement.primaryParameter.unitText) {
      deviceMeasurement += ` ${measurement.primaryParameter.unitText}`;
    }
  }
  if (measurement.lastSeen) {
    deviceMeasurement += ` @ ${measurement.lastSeen.format(
      defaultDateFormat + " " + defaultTimeFormat
    )}`;
  }
  return deviceMeasurement;
};

export const extractDeviceLocation = (
  device: IDevice
): IGeolocation | undefined => {
  if (
    device.autoLocation &&
    device.autoLocation.geo.latitude &&
    device.autoLocation.geo.longitude &&
    device.autoLocation.geo.latitude.length > 0 &&
    device.autoLocation.geo.longitude.length > 0
  ) {
    return {
      lat: parseFloat(device.autoLocation.geo.latitude),
      lng: parseFloat(device.autoLocation.geo.longitude),
      id: device.shortId,
      name: device.name,
      measurement: generateDeviceDataPointString(device.measurements.values)
    };
  }
  if (
    device.manualLocation &&
    device.manualLocation.geo.latitude &&
    device.manualLocation.geo.longitude &&
    device.manualLocation.geo.latitude.length > 0 &&
    device.manualLocation.geo.longitude.length > 0
  ) {
    return {
      lat: parseFloat(device.manualLocation.geo.latitude),
      lng: parseFloat(device.manualLocation.geo.longitude),
      id: device.shortId,
      name: device.name,
      measurement: generateDeviceDataPointString(device.measurements.values),
      primaryParameter: device.measurements.primaryParameter
    };
  }
};

export const extractCollectionLocations = (
  collection: IDeviceCollection
): IGeolocation[] => {
  return collection.members
    .map((device: IDevice) => extractDeviceLocation(device))
    .filter((location?: IGeolocation) => !!location) as IGeolocation[];
};

export const getBounds = (
  locations: IGeolocation[],
  height?: number,
  width?: number
) => {
  const { defaultZoom, mapCenter } = Configuration.geo;
  if (!width || !height) {
    throw new TypeError(
      "Width and Height of container are required for device collection"
    );
  }
  let center = mapCenter;
  let zoom = defaultZoom.noDevice;
  if (locations.length === 1) {
    center = locations[0];
    zoom = defaultZoom.oneDevice;
  }
  if (locations.length > 1) {
    const latArray: number[] = locations.map(location => location.lat);
    const lngArray: number[] = locations.map(location => location.lng);

    const bounds = {
      ne: {
        lat: Math.max(...latArray),
        lng: Math.max(...lngArray)
      },
      sw: {
        lat: Math.min(...latArray),
        lng: Math.min(...lngArray)
      }
    };
    if (bounds.ne.lat === bounds.sw.lat && bounds.ne.lng === bounds.sw.lng) {
      center = bounds.ne;
      zoom = defaultZoom.oneDevice;
      return { center, zoom };
    }
    const fittedBounds = fitBounds(bounds, { width, height });
    fittedBounds.zoom -= 1;
    return fittedBounds;
  }
  return { center, zoom };
};
