import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { unregister } from "./core/configuration/registerServiceWorker";
import { store } from "./core/store";
import Devices from "./Devices/Devices";
import EditDevice from "./Devices/EditDevice";
import Root from "./Root/Root";
import { globalStyles } from "./core/styles.global";
import EditUserProfile from "./Profile/EditUserProfile";
import Location from "./Location/Location";
import ExportDevice from "./ExportDevice/ExportDevice";
import Resources from "./Resources/Resources";
import DeviceAlerts from "./Devices/DeviceAlerts/DeviceAlerts";
import EditDeviceAlert from "./Devices/DeviceAlerts/EditDeviceAlert";
import Dashboard from "./Dashboard/Dashboard";
import versionWarcher from "./core/lifecycle/versionWatcher";
import ScrollTop from "./core/lifecycle/ScrollTop";
import DeviceParameters from "./Devices/DeviceParameters/DeviceParameters";
import { history } from "./core/configuration/history";
import AddNewDevice from "./AddNewDevice/AddNewDevice";
import { InstallMobileApp } from "./InstallMobileApp/InstallMobileApp";
import { fetchStyles } from "./core/withThemeProvider";

const renderResources = (props: any) => (
  <Resources key={props.match.params.id} {...props} />
);

const App = () => (
  <Provider store={store}>
    <SnackbarProvider maxSnack={3}>
      <Router history={history}>
        <ScrollTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Root>
              <Switch>
                <Route exact={true} path="/" component={Dashboard} />
                <Route exact={true} path="/devices" component={Devices} />
                <Route path="/resources/:id" render={renderResources} />
                <Route path="/locations" component={Location} />
                <Route
                  exact={true}
                  path="/devices/add"
                  component={AddNewDevice}
                />
                <Route
                  exact={true}
                  path="/devices/:id"
                  component={EditDevice}
                />
                <Route
                  exact={true}
                  path="/devices/:id/graph"
                  component={DeviceParameters}
                />
                <Route
                  exact={true}
                  path="/devices/:id/alerts"
                  component={DeviceAlerts}
                />
                <Route
                  path="/devices/:id/alerts/:alertId"
                  component={EditDeviceAlert}
                />
                <Route path="/users/:id" component={EditUserProfile} />
                <Route path="/export" component={ExportDevice} />
                <Route path="/mobileapp" component={InstallMobileApp} />
              </Switch>
            </Root>
          </MuiPickersUtilsProvider>
        </ScrollTop>
      </Router>
    </SnackbarProvider>
  </Provider>
);
fetchStyles().finally(() => {
  ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);
  unregister();
  globalStyles();
  versionWarcher();
});
