import * as React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";

import { ChartContainer } from "../ResourceVolumeChanges";
import { StyledProgress } from "../../Resources";
import { IRainChance } from "../../models/IResource";
import { Configuration } from "../../../core/configuration/config";

interface IRainChanceChart {
  rainChance?: IRainChance[];
  loading: boolean;
}

export const RainChanceChart: React.FunctionComponent<
  IRainChanceChart
> = props => {
  const formatLabel = (label: string | number): React.ReactText => label + "%";
  const { rainChance, loading } = props;
  return (
    <ChartContainer height={Configuration.isMobile ? 450 : 250}>
      {loading && <StyledProgress />}
      {!loading && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            margin={{ top: 30, bottom: 20, left: 10, right: 20 }}
            data={rainChance}
            barGap={0}
            barCategoryGap={0}
          >
            <CartesianGrid stroke="#e5e5e5" />
            <XAxis
              dataKey="atLeastMm"
              interval={Configuration.isMobile ? 0 : "preserveStartEnd"}
            />
            <YAxis dataKey="chance" domain={[0, 100]} unit="%" />
            <Bar dataKey="chance" unit="%">
              <LabelList
                dataKey="chance"
                position="top"
                formatter={formatLabel}
              />
              {rainChance
                ? rainChance.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={Configuration.colors.rainForecastChartColors[index]}
                    />
                  ))
                : ""}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      )}
    </ChartContainer>
  );
};
RainChanceChart.displayName = "RainChanceChart";
