import * as React from "react";
import styled from "styled-components";
import { Button, TableRow, ListItemText, InputLabel } from "@material-ui/core";
import {
  Edit as EditIcon,
  BarChart as ChartIcon,
  AddAlert
} from "@material-ui/icons";

import { LastSeenCell } from "../DeviceTableCustomCells/LastSeenCell";
import { ChartCell } from "../DeviceTableCustomCells/ChartCell";
import { TemperatureCell } from "../DeviceTableCustomCells/TemperatureCell";
import { BatteryCell } from "../DeviceTableCustomCells/BatteryCell";
import { NoStyleLink } from "../../../components/sharedStyledComponents";
import { CustomizedTableCell } from "../DeviceTable";
import { IDevice } from "../../models/IDevice";
import { theme } from "../../../core/withThemeProvider";
import {RSSICell} from "../DeviceTableCustomCells/RSSICell"

interface IDeviceTableRow {
  device: IDevice;
  hide: boolean;
}

export const NameSerial = styled(ListItemText)`
  && > * {
    font-size: 0.95em;
    white-space: normal;
    word-break: break-word;
  }
`;
NameSerial.displayName = "NameSerial";

export const StyledSerial = styled(InputLabel)`
  && {
    font-size: 0.9em;
    color: gray;
    white-space: normal;
    word-break: keep-all;
  }
`;
StyledSerial.displayName = "StyledSerial";

export const TableButton = styled(Button)`
  & svg {
    font-size: 18px;
    margin-left: 5px;
  }
`;
TableButton.displayName = "TableButton";

export const StyledAlertIcon = styled(AddAlert)`
  && {
    margin-right: ${theme.spacing(1)}px;
    font-size: 20px;
  }
`;
StyledAlertIcon.displayName = "StyledAlertIcon";

export const DeviceTableRow: React.FunctionComponent<
  IDeviceTableRow
> = props => {
  const { device, hide } = props;
  return (
    <TableRow>
      <CustomizedTableCell>
        <NameSerial
          primary={device.name}
          secondary={<StyledSerial>{device.serial}</StyledSerial>}
        />
      </CustomizedTableCell>
      <LastSeenCell measurements={device.measurements} />
      <ChartCell hide={hide ? 1 : 0} measurements={device.measurementsChart} />
      <TemperatureCell
        measurements={device.measurements}
        parameters={device.template.parameters}
      />
      <BatteryCell measurements={device.measurements} />
      <RSSICell measurements={device.measurements}/>
      <CustomizedTableCell hide={hide ? 1 : 0}>
        {device.note}
      </CustomizedTableCell>
      <CustomizedTableCell
        hide={hide ? 1 : 0}
        textalign="center"
        type="button"
        verticalalign="top"
        paddingtop="20px"
      >
        <NoStyleLink to={`/devices/${device.shortId}/alerts`}>
          <TableButton color="primary">
            Alerts <StyledAlertIcon />
          </TableButton>
        </NoStyleLink>
        <NoStyleLink to={`/devices/${device.shortId}/graph`}>
          <TableButton color="primary">
            Graph <ChartIcon />
          </TableButton>
        </NoStyleLink>
      </CustomizedTableCell>
      <CustomizedTableCell
        hide={hide ? 1 : 0}
        textalign="center"
        type="button"
        verticalalign="top"
        paddingtop="20px"
      >
        <NoStyleLink to={`/devices/${device.shortId}`}>
          <TableButton color="primary">
            Edit <EditIcon />
          </TableButton>
        </NoStyleLink>
      </CustomizedTableCell>
    </TableRow>
  );
};
