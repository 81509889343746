import React from "react";
import { TableRow, TableHead } from "@material-ui/core";

import { CustomizedTableCell } from "../../../components/DeviceTable";
import { TableLoadingIndicator } from "../../../../components/TableStyles";

interface IDeviceAlertTableHead {
  hide: boolean;
  isLoading?: boolean;
}
export const DeviceAlertTableHead: React.FunctionComponent<
  IDeviceAlertTableHead
> = ({ hide, isLoading }: IDeviceAlertTableHead) => {
  return (
    <TableHead>
      <TableRow>
        <CustomizedTableCell header={1} width="200px">
          Alert Parameter
        </CustomizedTableCell>
        <CustomizedTableCell header={1}>Message</CustomizedTableCell>
        <CustomizedTableCell header={1} width="125px">
          Threshold
        </CustomizedTableCell>
        <CustomizedTableCell header={1} width="125px">
          Type
        </CustomizedTableCell>
        <CustomizedTableCell
          header={1}
          width="125px"
          type="button"
          hide={hide ? 1 : 0}
        />
      </TableRow>
      {isLoading && <TableLoadingIndicator colspan={8} />}
    </TableHead>
  );
};
