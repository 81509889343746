import * as React from "react";
import styled from "styled-components";
import { Edit as EditIcon, BarChart as ChartIcon } from "@material-ui/icons";
import {
  List,
  ListItem,
  Divider,
  Hidden,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
  LinearProgress
} from "@material-ui/core";

import { IDevice } from "../models/IDevice";
import { Configuration } from "../../core/configuration/config";
import { NoStyleLink } from "../../components/sharedStyledComponents";
import { IInfluxTableQuery } from "../../Resources/models/IResource";
import { NameSerial } from "./DeviceTable/DeviceTableRow";
import { StyledAlertIcon } from "../EditDevice";
import { IPaginationView } from "../../core/models/MixinInterfaces";
import { Pagination } from "../../components/Pagination";

interface IProps {
  isLoading?: boolean;
  rows: IDevice[];
  pagination?: IPaginationView;
  totalItems?: number;
  onChangePage?: (page: string) => void;
}

interface IStyledChartIcon {
  danger: number;
}
export const StyledChartIcon = styled(ChartIcon)<IStyledChartIcon>`
  color: ${props =>
    props.danger
      ? Configuration.colors.icon.danger
      : Configuration.colors.icon.normal};
  && {
    font-size: 30px;
  }
`;
StyledChartIcon.displayName = "StyledChartIcon";

export const generateMeasurementString = (
  fullString: boolean,
  measurements?: IInfluxTableQuery
) => {
  const { defaultDateFormat, defaultTimeFormat } = Configuration;
  let result = "";
  if (measurements) {
    const { primaryParameter, temperature, lastSeen } = measurements;
    if (primaryParameter) {
      result += `${primaryParameter.value}${primaryParameter.unitText}`;
    }
    if (lastSeen) {
      result += ` @ ${lastSeen.format(
        defaultDateFormat + " " + defaultTimeFormat
      )}`;
    }
    if (fullString && temperature) {
      result += ` ${temperature.value}${temperature.unitText}`;
    }
  }
  return result;
};
export const DeviceList: React.FunctionComponent<IProps> = ({
  isLoading,
  totalItems,
  onChangePage,
  pagination,
  rows: devices
}: IProps) => {
  const { battery: batteryThreshold } = Configuration.threshold;
  return (
    <React.Fragment>
      {isLoading && <LinearProgress />}
      <List>
        {devices.map(
          (
            {
              id,
              serial,
              shortId,
              name,
              measurements: { values: deviceMeasurements }
            },
            index
          ) => (
            <React.Fragment key={id}>
              <Hidden smUp={true}>
                <ListItem button={true}>
                  <NoStyleLink to={`/devices/${shortId}/alerts`}>
                    <ListItemIcon>
                      <StyledAlertIcon />
                    </ListItemIcon>
                  </NoStyleLink>
                  <NoStyleLink to={`/devices/${shortId}/graph`}>
                    <ListItemIcon>
                      <StyledChartIcon
                        danger={
                          deviceMeasurements &&
                          deviceMeasurements.chargeLevel &&
                          deviceMeasurements.chargeLevel.value <
                            batteryThreshold
                            ? 1
                            : 0
                        }
                      />
                    </ListItemIcon>
                  </NoStyleLink>
                  <NoStyleLink to={`/devices/${shortId}`}>
                    <NameSerial
                      primary={`${name}\n${generateMeasurementString(
                        false,
                        deviceMeasurements
                      )}`}
                      secondary={serial}
                    />
                  </NoStyleLink>
                </ListItem>
              </Hidden>
              <Hidden xsDown={true}>
                <ListItem>
                  <ListItemIcon>
                    <NoStyleLink to={`/devices/${shortId}/alerts`}>
                      <StyledAlertIcon />
                    </NoStyleLink>
                  </ListItemIcon>
                  <ListItemIcon>
                    <NoStyleLink to={`/devices/${shortId}/graph`}>
                      <StyledChartIcon
                        danger={
                          deviceMeasurements &&
                          deviceMeasurements.chargeLevel &&
                          deviceMeasurements.chargeLevel.value <
                            batteryThreshold
                            ? 1
                            : 0
                        }
                      />
                    </NoStyleLink>
                  </ListItemIcon>
                  <NameSerial
                    primary={`${name}\n${generateMeasurementString(
                      true,
                      deviceMeasurements
                    )}`}
                    secondary={serial}
                  />
                  <ListItemSecondaryAction>
                    <NoStyleLink to={`/devices/${shortId}`}>
                      <IconButton aria-label="Edit">
                        <EditIcon />
                      </IconButton>
                    </NoStyleLink>
                  </ListItemSecondaryAction>
                </ListItem>
              </Hidden>
              {devices.length - 1 !== index && <Divider />}
            </React.Fragment>
          )
        )}
      </List>
      {pagination && onChangePage && totalItems !== undefined && (
        <Pagination
          isLoading={isLoading}
          onChangePage={onChangePage}
          pagination={pagination}
          totalItems={totalItems}
        />
      )}
    </React.Fragment>
  );
};
DeviceList.displayName = "DeviceList";
