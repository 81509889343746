import axios, { AxiosResponse } from "axios";

import { ActionType } from "../../core/ActionTypes";
import { IUserProfile, IUserProfileAPI } from "../models/IUserProfile";
import {
  Configuration,
  eramaApiHeaders,
  esmaApiHeaders
} from "../../core/configuration/config";
import {
  getSubscriptionsWithRoles,
  profileFromApi,
  profileToApi
} from "../services/UserProfileService";
import { Thunk } from "../../core/store";
import { userProfileReducerTypes } from "../reducers/userProfileReducer";
import { createErrorConsoleMessage } from "../../core/utilities/ServiceUtilities";
import {
  checkError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";
import { subscriptionFromApi } from "../../Subscriptions/services/SubscriptionService";
import { roleFromApi } from "../../Roles/services/RolesService";
import {getResources, refreshToken} from "../../actions/globalStateAction";

export const getUserById: Thunk<userProfileReducerTypes> = (userId: string) => {
  return async (dispatch): Promise<IUserProfile | SnackbarError> => {
    const { APIBaseURL } = Configuration;
    dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: true });
    try {
      const result: AxiosResponse<IUserProfileAPI> = await axios.get(
        Configuration.EsmaAPIUrl + "/users/" + userId,
        esmaApiHeaders
      );
      const user = profileFromApi(result.data);
      dispatch({
        type: ActionType.GET_USER_PROFILE,
        payload: user
      });

      const subscriptionQueries = Object.keys(result.data.hasRoles).map(
        subscriptionId =>
          axios.get(APIBaseURL + subscriptionId, { ...esmaApiHeaders })
      );
      const roleQueries = Object.keys(result.data.hasRoles)
        .map(subscription => result.data.hasRoles[subscription])
        .reduce((flatten, arr) => [...flatten, ...arr])
        .filter((role, position, arr) => arr.indexOf(role) === position)
        .map(roleId => axios.get(APIBaseURL + roleId, { ...eramaApiHeaders }));
      const [subscriptions, roles] = await Promise.all([
        Promise.all(subscriptionQueries),
        Promise.all(roleQueries)
      ]);

      dispatch({
        type: ActionType.GET_USER_PROFILE_SUBSCRIPTIONS_WITH_ROLES,
        payload: getSubscriptionsWithRoles(
          result.data.hasRoles,
          subscriptions.map(subscription =>
            subscriptionFromApi(subscription.data)
          ),
          roles.map(role => roleFromApi(role.data))
        )
      });
      dispatch(refreshToken());
      return user;
    } catch (err) {
      if (err.response && err.response.config.url.indexOf(userId) >= 0) {
        dispatch(refreshToken());
      }
      createErrorConsoleMessage(err, "getUserById", { userId });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: false });
    }
  };
};

export const updateUserById: Thunk<userProfileReducerTypes> = (
  profile: IUserProfile
) => {
  return async (dispatch): Promise<IUserProfile | SnackbarError> => {
    const { APIBaseURL } = Configuration;
    dispatch({ type: ActionType.REINITIALIZE });
    dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: true });
    try {
      const result: AxiosResponse<IUserProfileAPI> = await axios.put(
        Configuration.EsmaAPIUrl + "/users/" + profile.shortId,
        profileToApi(profile),
        esmaApiHeaders
      );
      const user = profileFromApi(result.data);
      dispatch({
        type: ActionType.UPDATE_USER_PROFILE,
        payload: user
      });

      const subscriptionQueries = Object.keys(result.data.hasRoles).map(
        subscriptionId =>
          axios.get(APIBaseURL + subscriptionId, { ...esmaApiHeaders })
      );
      const roleQueries = Object.keys(result.data.hasRoles)
        .map(subscription => result.data.hasRoles[subscription])
        .reduce((flatten, arr) => [...flatten, ...arr])
        .filter((role, position, arr) => arr.indexOf(role) === position)
        .map(roleId => axios.get(APIBaseURL + roleId, { ...eramaApiHeaders }));
      const [subscriptions, roles] = await Promise.all([
        Promise.all(subscriptionQueries),
        Promise.all(roleQueries)
      ]);

      await dispatch(refreshToken(user.activeRole, user.activeSubscription));
      await dispatch(getResources())
      dispatch({
        type: ActionType.GET_USER_PROFILE_SUBSCRIPTIONS_WITH_ROLES,
        payload: getSubscriptionsWithRoles(
          result.data.hasRoles,
          subscriptions.map(subscription =>
            subscriptionFromApi(subscription.data)
          ),
          roles.map(role => roleFromApi(role.data))
        )
      });
      return user;
    } catch (err) {
      createErrorConsoleMessage(err, "updateUserById", { profile });
      return checkError(err);
    } finally {
      dispatch({ type: ActionType.USER_PROFILE_LOADING, payload: false });
    }
  };
};
