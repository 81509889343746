import * as React from "react";
import {
  SwipeableViewsProps,
  SwipeableViewsState
} from "react-swipeable-views";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { RainChanceChart } from "./RainChanceChart";
import { ResourceVolumeChanceMain } from "./ResourceVolumeChanceMain";
import { ResourceVolumeChanceAdditional } from "./ResourceVolumeChanceAdditional";
import { IRainChance } from "../../models/IResource";

interface IResourcePeriodPrediction {
  loading: boolean;
  volumeChance75?: number;
  volumeChance50?: number;
  volumeChance25?: number;
  volumeChance75Unit?: string;
  volumeChance50Unit?: string;
  volumeChance25Unit?: string;
  rainChance: IRainChance[];
}

export const TabCaption = styled.span`
  font-size: 15px;
  padding: 0 0 30px;
`;
TabCaption.displayName = "TabCaption";

export const ResourcePredictionTab: React.FunctionComponent<
  SwipeableViewsProps & SwipeableViewsState & IResourcePeriodPrediction
> = props => {
  const {
    loading,
    volumeChance75,
    volumeChance50,
    volumeChance25,
    volumeChance75Unit,
    volumeChance50Unit,
    volumeChance25Unit,
    rainChance
  } = props;
  return (
    <Grid container={true} direction="row">
      <Grid
        item={true}
        container={true}
        xs={12}
        sm={12}
        md={6}
        lg={7}
        xl={7}
        justify="center"
      >
        <RainChanceChart rainChance={rainChance} loading={loading} />
        <TabCaption>RAIN CHANCE (mm)</TabCaption>
      </Grid>
      <Grid
        item={true}
        container={true}
        direction="column"
        xl={5}
        lg={5}
        md={6}
        sm={12}
        xs={12}
        alignItems="center"
        justify="space-between"
      >
        <Grid
          container={true}
          item={true}
          direction="column"
          alignItems="center"
          justify="space-between"
        >
          <ResourceVolumeChanceMain
            chance={75}
            volume={volumeChance75}
            unit={volumeChance75Unit}
            loading={loading}
          />
          <ResourceVolumeChanceAdditional
            chance={50}
            volume={volumeChance50}
            unit={volumeChance50Unit}
            loading={loading}
          />
          <ResourceVolumeChanceAdditional
            chance={25}
            volume={volumeChance25}
            unit={volumeChance25Unit}
            loading={loading}
          />
        </Grid>
        <TabCaption>Volume chance</TabCaption>
      </Grid>
    </Grid>
  );
};
ResourcePredictionTab.displayName = "ResourcePredictionTab";
