import * as React from "react";
import { LinearProgress } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import { StyledLastSeenIcon } from "../../../components/ThermometerIcon";
import { Configuration } from "../../../core/configuration/config";

export const LastSeenCell: React.FunctionComponent<IIconCell> = props => {
  const { isLoading, values } = props.measurements;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          {values && values.lastSeen && (
            <React.Fragment>
              <StyledLastSeenIcon
                danger={Configuration.threshold.time(values.lastSeen) ? 1 : 0}
              />
              <br />
              <span>
                {values.lastSeen.format(Configuration.defaultDateFormat)}
              </span>
              <br />
              <span>
                {values.lastSeen.format(Configuration.defaultTimeFormat)}
              </span>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </CustomizedTableCell>
  );
};
