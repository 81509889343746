import { ActionType } from "../../core/ActionTypes";
import { ILocation, initialLocations } from "../models/IGeolocation";

export type locationReducerTypes =
  | { type: ActionType.DEVICE_LOCATIONS_LOADING; payload: boolean }
  | { type: ActionType.GET_DEVICE_LOCATIONS; payload: ILocation }
  | { type: ActionType.REINITIALIZE };

export function locationReducer(
  state: ILocation = { ...initialLocations },
  action: locationReducerTypes
): ILocation {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = {
        ...initialLocations
      };
      break;
    case ActionType.DEVICE_LOCATIONS_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.GET_DEVICE_LOCATIONS:
      state = {
        ...state,
        ...action.payload
      };
      break;
    default:
      break;
  }
  return state;
}
