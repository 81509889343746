import moment from "moment";
import axios, { AxiosError } from "axios";
import { AxiosResponse } from "axios";

import { ActionType } from "../../core/ActionTypes";
import { IResourceData, initialResource } from "../models/IResource";
import { IDeviceApi } from "../../Devices/models/IDevice";
import { Configuration, ediApiHeaders } from "../../core/configuration/config";
import {
  getLastForecastsForPeriod,
  getRainChanceForPeriod,
  getResourceDepthData,
  getResourceMedianDataByPeriod,
  getResourceVolumeChanges,
  getResourceVolumeChangesForecast,
  getResourceVolumeData
} from "../services/InfluxService";
import { deviceFromApi } from "../../Devices/services/DeviceService";
import { mapInfluxData } from "../services/ResourceService";
import {
  createBlockableDispatch,
  createErrorConsoleMessage
} from "../../core/utilities/ServiceUtilities";
import { Thunk } from "../../core/store";
import { resourceReducerTypes } from "../reducers/resourceReducer";
import {
  checkError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";

export const setTabState = (tabId: number): resourceReducerTypes => {
  return { type: ActionType.RESOURCE_TAB_STATE, payload: tabId };
};

export const getResourceMeasurements: Thunk<resourceReducerTypes> = (
  deviceId: string
) => {
  return async (dispatch, _, opt): Promise<undefined | SnackbarError[]> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.INITIALIZE_RESOURCE_MEASUREMENTS,
      payload: initialResource
    });
    const today = {
      start: moment().startOf("day"),
      end: moment().endOf("day")
    };
    const error: (AxiosError | Error)[] = [];
    const resourceData: IResourceData = {};

    let resource = { ...initialResource };
    try {
      const deviceResponse: AxiosResponse<IDeviceApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices/" + deviceId,
        ediApiHeaders
      );
      resourceData.device = deviceFromApi(deviceResponse.data);
    } catch (e) {
      createErrorConsoleMessage(e, "getResourceMeasurements::getDevice");
      error.push(e);
    }
    if (!resourceData.device) {
      resource = mapInfluxData(resourceData);
      dispatch({
        type: ActionType.GET_RESOURCE_MEASUREMENTS,
        payload: resource
      });
      return;
    }

    const influxRequestArray = [];
    const influxRequest = {
      volumeData: getResourceVolumeData(deviceId, resourceData.device),
      depthData: getResourceDepthData(deviceId, resourceData.device),
      baseDataSubtYear: getResourceMedianDataByPeriod(
        deviceId,
        today.start
          .clone()
          .subtract(1, "year")
          .toISOString(),
        today.end
          .clone()
          .subtract(1, "year")
          .toISOString(),
        resourceData.device
      ),
      previousMonthBaseData: getResourceMedianDataByPeriod(
        deviceId,
        today.start
          .clone()
          .subtract(1, "month")
          .toISOString(),
        today.end.clone().toISOString(),
        resourceData.device
      ),
      previousMonthSubtYearBaseData: getResourceMedianDataByPeriod(
        deviceId,
        today.start
          .clone()
          .subtract(1, "month")
          .subtract(1, "year")
          .toISOString(),
        today.end
          .clone()
          .subtract(1, "year")
          .toISOString(),
        resourceData.device
      ),
      previousThreeMonthsBaseData: getResourceMedianDataByPeriod(
        deviceId,
        today.start
          .clone()
          .subtract(3, "month")
          .toISOString(),
        today.end.clone().toISOString(),
        resourceData.device
      ),
      previousThreeMonthsSubtYearBaseData: getResourceMedianDataByPeriod(
        deviceId,
        today.start
          .clone()
          .subtract(3, "month")
          .subtract(1, "year")
          .toISOString(),
        today.end
          .clone()
          .subtract(1, "year")
          .toISOString(),
        resourceData.device
      ),
      volumeChanges: getResourceVolumeChanges(
        deviceId,
        today.start
          .clone()
          .startOf("month")
          .subtract(4, "month")
          .toISOString(),
        today.end
          .clone()
          .endOf("month")
          .toISOString(),
        resourceData.device
      ),
      volumeChangesForecast: getResourceVolumeChangesForecast(
        deviceId,
        today.start
          .clone()
          .utc()
          .add(1, "month")
          .startOf("month")
          .toISOString(),
        today.end
          .clone()
          .endOf("month")
          .add(3, "month")
          .toISOString(),
        resourceData.device
      ),
      nextMonth: getLastForecastsForPeriod(
        deviceId,
        "1m",
        today.start.clone().toISOString(),
        today.end
          .clone()
          .add(1, "month")
          .toISOString(),
        resourceData.device
      ),
      next3Month: getLastForecastsForPeriod(
        deviceId,
        "3m",
        today.start
          .clone()
          .add(2, "month")
          .toISOString(),
        undefined,
        resourceData.device
      ),
      rainChanceNextMonth: getRainChanceForPeriod(
        deviceId,
        today.start.clone().toISOString(),
        resourceData.device.weather,
        today.end
          .clone()
          .add(1, "month")
          .toISOString()
      ),
      rainChanceNext3Month: getRainChanceForPeriod(
        deviceId,
        today.start
          .clone()
          .add(2, "month")
          .toISOString(),
        resourceData.device.weather
      )
    };

    // object.values unsupported in IE11
    for (const property in influxRequest) {
      if (influxRequest.hasOwnProperty(property)) {
        influxRequestArray.push(influxRequest[property]);
      }
    }

    const influxResult = await Promise.all(influxRequestArray);
    influxResult.forEach((result, index) => {
      if (result instanceof Error) {
        error.push(result);
      } else {
        switch (index) {
          case 0:
            resourceData.volumeData = result;
            break;
          case 1:
            resourceData.depthData = result;
            break;
          case 2:
            resourceData.baseDataSubtYear = result;
            break;
          case 3:
            resourceData.previousMonthBaseData = result;
            break;
          case 4:
            resourceData.previousMonthSubtYearBaseData = result;
            break;
          case 5:
            resourceData.previousThreeMonthsBaseData = result;
            break;
          case 6:
            resourceData.previousThreeMonthsSubtYearBaseData = result;
            break;
          case 7:
            resourceData.chart = resourceData.chart
              ? [...resourceData.chart, ...result]
              : result;
            break;
          case 8:
            resourceData.chart = resourceData.chart
              ? [...resourceData.chart, ...result]
              : result;
            break;
          case 9:
            resourceData.nextMonth = result;
            break;
          case 10:
            resourceData.next3Month = result;
            break;
          case 11:
            resourceData.rainChanceNextMonth = result;
            break;
          case 12:
            resourceData.rainChanceNext3Month = result;
            break;
          default:
            break;
        }
      }
    });

    resource = mapInfluxData(resourceData);
    blockableDispatch({
      type: ActionType.GET_RESOURCE_MEASUREMENTS,
      payload: resource
    });
    if (error.length > 0) {
      return error.map(error => checkError(error));
    }
  };
};
