import axios, { AxiosResponse } from "axios";

import { Configuration, ediApiHeaders } from "../../core/configuration/config";
import { IDeviceCollectionApi } from "../../Devices/models/IDevice";
import { ActionType } from "../../core/ActionTypes";
import { getDeviceCollectionLocation } from "../services/LocationService";
import { deviceCollectionFromApi } from "../../Devices/services/DeviceService";
import { Thunk } from "../../core/store";
import { locationReducerTypes } from "../reducers/locationReducer";
import {
  createBlockableDispatch,
  createErrorConsoleMessage
} from "../../core/utilities/ServiceUtilities";
import { DevicePrimaryParameter, ILocation } from "../models/IGeolocation";
import {
  checkError,
  SnackbarError
} from "../../core/utilities/SnackbarUtilities";
import { getTableMeasurements } from "../../Resources/services/InfluxService";

export const getLocations: Thunk<locationReducerTypes> = (
  height: number,
  width: number
) => {
  return async (dispatch, _, opt): Promise<ILocation | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_LOCATIONS_LOADING,
      payload: true
    });
    try {
      const {
        data: deviceDataCollection
      }: AxiosResponse<IDeviceCollectionApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices",
        ediApiHeaders
      );
      const deviceCollection = deviceCollectionFromApi(deviceDataCollection);
      const measurements = await getTableMeasurements(deviceCollection.members);
      deviceCollection.members.forEach(device => {
        device.measurements = {
          isLoading: false
        };
        if (device.template.parameters) {
          const parameters = device.template.parameters.map(parameter =>
            parameter.name.toLowerCase()
          );
          if (parameters.indexOf("level") >= 0) {
            device.measurements.primaryParameter = DevicePrimaryParameter.LEVEL;
          } else if (parameters.indexOf("pressure") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.PRESSURE;
          } else if (parameters.indexOf("temperature") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.TEMPERATURE;
          } else if (parameters.indexOf("turbidity") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.TURBIDITY;
          } else if (parameters.indexOf("chargelevel") >= 0) {
            device.measurements.primaryParameter =
              DevicePrimaryParameter.CHARGE_LEVEL;
          }
        }
        measurements.forEach(measurement => {
          if (device.shortId === measurement.device) {
            device.measurements.values = measurement;
          }
        });
      });
      const location = getDeviceCollectionLocation(
        deviceCollection,
        height,
        width
      );
      blockableDispatch({
        type: ActionType.GET_DEVICE_LOCATIONS,
        payload: location
      });
      return location;
    } catch (err) {
      createErrorConsoleMessage(err, "getLocations", { height, width });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_LOCATIONS_LOADING,
        payload: false
      });
    }
  };
};
