import { ActionType } from "../../../core/ActionTypes";
import {
  initialViewDeviceParameters,
  IParameterUnits,
  IViewDeviceParameters
} from "../models/IViewDeviceParameters";

export type viewDeviceParametersReducerTypes =
  | {
      type: ActionType.DEVICE_PARAMETERS_LOADING;
      payload: boolean;
    }
  | {
      type: ActionType.GET_DEVICE_PARAMETERS;
      payload: {
        device: string;
        parameters: {
          parameterUnits: IParameterUnits;
          parameterList: string[];
        };
      };
    }
  | {
      type: ActionType.DEVICE_PARAMETERS_CHART_LOADING;
      payload: boolean;
    }
  | {
      type: ActionType.REFRESH_DEVICE_PARAMETER_OPTIONS;
      payload: IViewDeviceParameters;
    }
  | {
      type: ActionType.CLEAR_VIEW_DEVICE_PARAMETER;
    }
  | {
      type: ActionType.DEVICE_PARAMETER_EXPANSION_STATE;
    }
  | {
      type: ActionType.REINITIALIZE;
    };

export function deviceParametersReducer(
  state: IViewDeviceParameters = { ...initialViewDeviceParameters },
  action: viewDeviceParametersReducerTypes
): IViewDeviceParameters {
  switch (action.type) {
    case ActionType.REINITIALIZE:
      state = {
        ...initialViewDeviceParameters
      };
      break;
    case ActionType.DEVICE_PARAMETER_EXPANSION_STATE:
      state = {
        ...state,
        panelExpanded: !state.panelExpanded
      };
      break;
    case ActionType.CLEAR_VIEW_DEVICE_PARAMETER:
      state = {
        ...initialViewDeviceParameters
      };
      break;
    case ActionType.DEVICE_PARAMETERS_CHART_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.REFRESH_DEVICE_PARAMETER_OPTIONS:
      state = {
        ...state,
        ...action.payload,
        panelExpanded: false
      };
      break;
    case ActionType.DEVICE_PARAMETERS_LOADING:
      state = {
        ...state,
        isLoading: action.payload
      };
      break;
    case ActionType.GET_DEVICE_PARAMETERS:
      const level = action.payload.parameters.parameterList
        .filter(parameter => parameter.toUpperCase() === "LEVEL")
        .pop();
      const pressure = action.payload.parameters.parameterList
        .filter(parameter => parameter.toUpperCase() === "PRESSURE")
        .pop();
      const turbidity = action.payload.parameters.parameterList
        .filter(parameter => parameter.toUpperCase() === "TURBIDITY")
        .pop();
      const temperature = action.payload.parameters.parameterList
        .filter(parameter => parameter.toUpperCase() === "TEMPERATURE")
        .pop();
      state = {
        ...state,
        parameterUnits: action.payload.parameters.parameterUnits,
        parameter: level
          ? level
          : pressure
          ? pressure
          : turbidity
          ? turbidity
          : temperature
          ? temperature
          : state.parameter,
        parameterList: action.payload.parameters.parameterList,
        device: action.payload.device
      };
      break;
    default:
      break;
  }
  return state;
}
