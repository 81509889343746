import * as React from "react";
import MuiSelect, {
  SelectProps as MuiSelectProps
} from "@material-ui/core/Select";
import { FieldProps, getIn } from "formik";

import { Omit } from "../core/models/types";
import styled from "styled-components";
import {FormHelperText, FormControl} from "@material-ui/core";

export type ISelectProps = FieldProps & Omit<MuiSelectProps, "value">;

export const fieldToSelect = ({
  field,
  form: { isSubmitting },
  disabled = false,
  ...props
}: ISelectProps): MuiSelectProps => {
  return {
    disabled: isSubmitting || disabled,
    ...field,
    ...props
  };
};

export const Select: React.ComponentType<ISelectProps> = (
  props: ISelectProps
) => {
  const { errors, touched } = props.form;
  const { name } = props.field;
  const errorList = getIn(errors, name);
  const touchedList = getIn(touched, name);
  return (
    <React.Fragment>
      <MuiSelect error={touchedList && !!errorList} {...fieldToSelect(props)} />
      {touchedList && !!errorList && (
        <FormHelperText error={true}>{errorList}</FormHelperText>
      )}
    </React.Fragment>
  );
};

Select.displayName = "FormikMaterialUISelect";

export const StyledSelect = styled(FormControl)`
  &&& {
    width: 100%;
    margin-bottom: 15px;
    text-align: left;
  }
`;
StyledSelect.displayName = "StyledSelect";
