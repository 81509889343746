import * as React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { Configuration } from "../../../core/configuration/config";
import { theme } from "../../../core/withThemeProvider";
import { IViewDeviceParameters } from "../models/IViewDeviceParameters";

interface ITickProps {
  x: number;
  y: number;
  payload: any;
}
export const DeviceParameterXAxisTick: React.FunctionComponent<ITickProps> = ({
  x,
  y,
  payload
}: ITickProps) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        {payload.value}
      </text>
    </g>
  );
};

interface IProps {
  data: IViewDeviceParameters;
}

export const ViewDeviceParameterChart: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const { chart, parameterList, parameter } = props.data;
  const {
    cartesianGrid,
    viewDeviceParameter: { max, mean, median, min }
  } = Configuration.colors.charts;
  const units = chart.length > 0 ? chart[0].unit : "";
  const value = parameterList.filter(param => param === parameter).pop();
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={chart}
        margin={{ bottom: 80, left: 30, right: 0, top: 30 }}
      >
        <CartesianGrid stroke={cartesianGrid} />
        <XAxis
          dataKey={"time"}
          axisLine={false}
          tick={DeviceParameterXAxisTick}
        />
        <YAxis
          domain={chart.length > 0 ? [0, "auto"] : [0, 2]}
          label={{
            position: "top",
            offset: 15,
            value: value && units ? value + "," + units : ""
          }}
          axisLine={false}
        />
        <Area
          type="monotone"
          dataKey="value"
          fillOpacity={0.15}
          fill={theme.palette.primary.main}
          unit={units}
          dot={{ strokeWidth: 1, r: 1 }}
          stroke={theme.palette.primary.main}
          connectNulls={true}
          strokeWidth={1}
        />
        <Area
          type="monotone"
          dataKey="median"
          fillOpacity={0.15}
          fill={median}
          unit={units}
          dot={false}
          stroke={median}
          connectNulls={true}
          strokeWidth={1}
        />
        <Area
          type="monotone"
          dataKey="average"
          fillOpacity={0.15}
          fill={mean}
          unit={units}
          dot={false}
          stroke={mean}
          connectNulls={true}
          strokeWidth={1}
        />
        <Area
          type="monotone"
          dataKey="min"
          fillOpacity={0.15}
          fill={min}
          unit={units}
          dot={false}
          stroke={min}
          connectNulls={true}
          strokeWidth={1}
        />
        <Area
          type="monotone"
          dataKey="max"
          fillOpacity={0.15}
          fill={max}
          unit={units}
          dot={false}
          stroke={max}
          connectNulls={true}
          strokeWidth={1}
        />
        <Tooltip />
      </AreaChart>
    </ResponsiveContainer>
  );
};
ViewDeviceParameterChart.displayName = "ViewDeviceParameterChart";
