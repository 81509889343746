import * as React from "react";
import styled from "styled-components";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { theme } from "../core/withThemeProvider";
import { UUID } from "../util/validators";
import { capitalizeFirstLetter } from "../core/utilities/ServiceUtilities";

export const BreadcrumbLink = styled(Link)`
  color: ${theme.palette.primary.main};
  text-decoration: none;
`;
BreadcrumbLink.displayName = "BreadcrumbLink";

export const StyledBreadcrumbs = styled.div`
  background: white;
  padding: 20px;
  font-size: 13px;
  border-bottom: 1px solid #e9e9e9;
  & > li {
    text-decoration: none;
    display: inline;
    ::before {
      content: "⇒";
      font-weight: bold;
      padding: 0 6px 0 6px;
      color: #808080;
    }
  }
`;
StyledBreadcrumbs.displayName = "StyledBreadcrumbs";

export const Breadcrumbs: React.FunctionComponent = () => {
  return (
    <StyledBreadcrumbs>
      <Route path="/:path" component={BreadcrumbsItem} />
    </StyledBreadcrumbs>
  );
};
Breadcrumbs.displayName = "Breadcrumbs";

export const BreadcrumbsItem: React.FunctionComponent = (props: any) => {
  const {
    match: {
      isExact,
      url,
      params: { path }
    }
  } = props;
  return (
    <React.Fragment>
      <li>
        {isExact ? (
          <span>
            {UUID.test(path) ? path.toLowerCase() : capitalizeFirstLetter(path)}
          </span>
        ) : (
          <BreadcrumbLink to={url || ""}>
            {UUID.test(path) ? path.toLowerCase() : capitalizeFirstLetter(path)}
          </BreadcrumbLink>
        )}
      </li>
      <Route path={`${url}/:path`} component={BreadcrumbsItem} />
    </React.Fragment>
  );
};
BreadcrumbsItem.displayName = "BreadcrumbsItem";
