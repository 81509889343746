import {Configuration} from "../configuration/config";

export function createBlockableDispatch(
  dispatch: (action: any) => void,
  key?: string
) {
  return (action: any): void => {
    dispatch({
      ...action,
      id: key
    });
  };
}

export function createErrorConsoleMessage(
  error: any,
  method: string,
  parameters?: any
) {
  if (Configuration.development) {
    console.error({ error, method, parameters });
  } else {
    console.error({ error });
  }
}

export const getUnit = (propertyId?: string) => {
  if (propertyId) {
    const property = getShortId(propertyId);
    if (property) {
      if (property === "id-turbidity-1") {
        return Configuration.baseUnits.percent;
      }
      const unit = property.split("-").pop();
      if (unit) {
        return Configuration.baseUnits[unit];
      }
    }
  }
};
export const getShortId = (id: string): string => {
  if (id.indexOf("/") > -1) {
    return id.split("/").pop() as string;
  }
  return id;
};

export const limitDecimal = (
  value: number,
  fractionDigitsMax: number
): number => {
  try {
    return parseFloat(value.toFixed(fractionDigitsMax));
  } catch (e) {
    return value;
  }
};

export const mobileAppAvailable = (): boolean =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const deepLinkRedirect = (fallbackUrl: string) => {
  let deeplinkEndpoint = "";
  switch (fallbackUrl) {
    case "/devices":
      deeplinkEndpoint = "device-list";
      break;
    case "/devices/add":
      deeplinkEndpoint = "add-device";
      break;
    default:
      break;
  }
  const now = new Date().valueOf();
  setTimeout(() => {
    if (new Date().valueOf() - now > 100) return;
    window.location.assign(mobileAppAvailable() ? "/mobileapp" : fallbackUrl);
  }, 0);
  window.location.assign("ellenex://" + deeplinkEndpoint);
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const getPathName = (pathName: string): string =>
  pathName.split("/").pop() || "";

