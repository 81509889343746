import { IParameters } from "../Devices/models/IDevice";

const EMAIL_REGEX: RegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PHONE_REGEX: RegExp = /^\+?[1-9]\d{10,16}$/;

const ALPHANUMERIC: RegExp = /^[A-Za-z0-9]+$/;

const STARTS_WITH_LETTER: RegExp = /^[A-Za-z]/;

const HEX: RegExp = /^[0-9a-fA-F]+$/;

export const UUID: RegExp = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const THRESHOLD_REGEX: RegExp = new RegExp(/^(-?[0-9]\d*(\.\d+)*)?$/);

const INTEGER_REGEX: RegExp = /^\d+$/;

const propertiesLength = {
  DEVIE_NAME_MAX: 64,
  DEVICE_NOTE_MAX: 512,
  USER_NAME_MAX: 64,
  USER_PHONE_MAX: 16,
  EXPRESSION_MAX_LENGTH: 256,
  TRANSFER_CODE_MAX: 8
};
const literals = {
  chars: " characters",
  name: {
    required: "Name is required",
    max: "Name should be maximum "
  }
};

export const Validators = {
  editDevice: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name) {
        error = literals.name.required;
      } else if (name.length > propertiesLength.DEVIE_NAME_MAX) {
        error =
          literals.name.max + propertiesLength.DEVIE_NAME_MAX + literals.chars;
      }
      return error;
    },
    note: (note: string) => {
      let error: string | null = null;
      if (note.length > propertiesLength.DEVICE_NOTE_MAX) {
        error =
          "Note should be maximum " +
          propertiesLength.DEVICE_NOTE_MAX +
          literals.chars;
      }
      return error;
    },
    latitude: (lat: number) => {
      if (lat < -90 || lat > 90) {
        return "Latitude should be less than 90 deg";
      }
      return null;
    },
    longitude: (lat: number) => {
      if (lat < -180 || lat > 180) {
        return "Longitude should be less than 180 deg";
      }
      return null;
    }
  },
  editDeviceAlert: {
    required: (value: string) =>
      value.toString() ? null : "Field is required",
    kind: (notificationKind: string) =>
      notificationKind.length > 0 ? null : "Method is required",
    phone: (code: string) => (phone: string) => {
      const phoneCode = code.replace("-", "");
      let error: string | null = null;
      if (!phone && !error) {
        error = "Phone number is required";
      } else if (
        phoneCode.length + phone.length >
        propertiesLength.USER_PHONE_MAX
      ) {
        error =
          "Phone number should be maximum " +
          propertiesLength.USER_PHONE_MAX +
          literals.chars;
      } else if (phone.length > 0 && !PHONE_REGEX.test(phoneCode + phone)) {
        error = "Please enter valid phone number";
      }
      return error;
    },
    threshold: (threshold: string) => {
      let error: string | null = null;
      if (!threshold && !error) {
        error = "Threshold is required";
      } else if (threshold.length > 0 && !THRESHOLD_REGEX.test(threshold)) {
        error = "Please enter valid threshold";
      }
      return error;
    },
    renotifyInterval: (value: string) => {
      let error: string | null = null;
      if (!value && !error) {
        error = "Renotify interval is required";
      } else if (value.length > 0 && !INTEGER_REGEX.test(value)) {
        error = "Renotify interval should be integer";
      }
      return error;
    },
    minimumRepeats: (value: string) => {
      let error: string | null = null;
      if (!value && !error) {
        error = "Minimum repeats is required";
      } else if (value.length > 0 && !INTEGER_REGEX.test(value)) {
        error = "Minimum repeats should be integer";
      }
      return error;
    },
    missedDataInterval: (value: string) => {
      let error: string | null = null;
      if (!value && !error) {
        error = "Missed data interval is required";
      } else if (value.length > 0 && !INTEGER_REGEX.test(value)) {
        error = "Missed data interval should be integer";
      }
      return error;
    }
  },
  editUserProfile: {
    name: (name: string) => {
      let error: string | null = null;
      if (!name) {
        error = literals.name.required;
      } else if (name.length > propertiesLength.USER_NAME_MAX) {
        error =
          literals.name.max + propertiesLength.USER_NAME_MAX + literals.chars;
      }
      return error;
    },
    email: (email: string) => {
      let error: string | null = null;
      if (!email) {
        error = "Email is required";
      } else if (email.length > 0 && !EMAIL_REGEX.test(email)) {
        error = "Please enter valid email address";
      }
      return error;
    },
    phone: (code: string) => (phone: string) => {
      const phoneCode = code.replace("-", "");
      let error: string | null = null;
      if (phoneCode.length + phone.length > propertiesLength.USER_PHONE_MAX) {
        error =
          "Phone number should be maximum " +
          propertiesLength.USER_PHONE_MAX +
          literals.chars;
      } else if (phone.length > 0 && !PHONE_REGEX.test(phoneCode + phone)) {
        error = "Please enter valid phone number";
      }
      return error;
    },
    subscription: (sub: string) => {
      if (!Boolean(sub)) {
        return "Subscription is required";
      }
      return null;
    },
    role: (role: string) => {
      if (!Boolean(role)) {
        return "Subscription is required";
      }
      return null;
    }
  },
  viewDeviceParameters: {
    required: (value: string | string[]) => {
      if (value.length === 0) {
        return "This field is required";
      } else {
        return null;
      }
    }
  },
  editDeviceParameters: {
    name: (fields: IParameters[], index: number) => {
      const properties = fields
        .map(parameter => parameter.name.toUpperCase())
        .filter((_: string, i: number) => i !== index);
      return (name: string) => {
        if (!name) {
          return literals.name.required;
        } else if (name.length > 0 && !ALPHANUMERIC.test(name)) {
          return "Name can contain only letters and numbers";
        } else if (name.length > 0 && !STARTS_WITH_LETTER.test(name)) {
          return "Name should start with letter";
        } else if (properties.indexOf(name.toUpperCase()) >= 0) {
          return "Names should be unique";
        }
        return null;
      };
    },
    expression: (expression: string) => {
      let error: string | null = null;
      if (expression.length > propertiesLength.EXPRESSION_MAX_LENGTH) {
        error =
          "Expression should be maximum " +
          propertiesLength.EXPRESSION_MAX_LENGTH +
          literals.chars;
      }
      return error;
    }
  },
  addNewDevice: {
    serial: (serial: string) => {
      if (serial.length === 0) {
        return "Serial is required";
      }
      return null;
    },
    transferCode: (code: string) => {
      let error: string | null = null;
      if (code.length < 1) {
        error = "Transfer Code is required";
      } else if (code.length > 8) {
        error =
          "Transfer Code should be maximum " +
          propertiesLength.TRANSFER_CODE_MAX +
          literals.chars;
      } else if (!HEX.test(code)) {
        error = "Only HEX number allowed";
      }
      return error;
    }
  }
};
