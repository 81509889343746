import * as React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { IVolumeChanceBlock } from "./ResourceVolumeChanceMain";
import { StyledProgress } from "../../Resources";
import { NotAvailable } from "../../../components/MeasurementNA";
import { StyledVolumeIcon } from "../../../components/VolumeIcon";

export const VolumeChanceSmallTitle = styled.span`
  font-size: 15px;
`;
VolumeChanceSmallTitle.displayName = "VolumeChanceSmallTitle";

export const VolumeChanceSmallDataBlock = styled(Grid)`
  display: flex;
  align-items: center;
`;
VolumeChanceSmallDataBlock.displayName = "VolumeChanceSmallDataBlock";

export const VolumeChanceSmallIcon = styled(StyledVolumeIcon)`
  height: 45px;
`;
VolumeChanceSmallIcon.displayName = "VolumeChanceSmallIcon";

export const VolumeChanceSmallValue = styled.div`
  padding-left: 12px;
  color: #29527d;
  font-size: 18px;
  font-weight: lighter;
  & > span {
    font-size: 10px;
    align-self: baseline;
  }
`;
VolumeChanceSmallValue.displayName = "VolumeChanceSmallValue";

export const VolumeChanceSmallWrapper = styled(Grid)`
  && {
    width: 300px;
    height: 90px;
    padding: 0 10px;
    border-top: 1px dashed #e6e6e6;
  }
`;
VolumeChanceSmallWrapper.displayName = "VolumeChanceSmallWrapper";

export const ResourceVolumeChanceAdditional: React.FunctionComponent<
  IVolumeChanceBlock
> = props => {
  const { volume, chance, unit, loading } = props;
  return (
    <VolumeChanceSmallWrapper
      container={true}
      item={true}
      justify="space-between"
      alignItems="center"
    >
      <VolumeChanceSmallTitle>{chance}% chance</VolumeChanceSmallTitle>
      <VolumeChanceSmallDataBlock item={true}>
        <VolumeChanceSmallIcon />
        <VolumeChanceSmallValue>
          {loading ? (
            <StyledProgress />
          ) : (
            <NotAvailable fontSize="20px" property={volume}>
              {volume}
              <span> {unit}</span>
            </NotAvailable>
          )}
        </VolumeChanceSmallValue>
      </VolumeChanceSmallDataBlock>
    </VolumeChanceSmallWrapper>
  );
};
ResourceVolumeChanceAdditional.displayName = "ResourceVolumeChanceAdditional";
