import JwtDecode from "jwt-decode";
import axios, { AxiosResponse } from "axios";
import { InfluxDB } from "influx";

import { history } from "../core/configuration/history";
import { ActionType } from "../core/ActionTypes";
import { ICurrentUser, IResourceLink } from "../models/IGlobalState";
import { IDeviceCollectionApi } from "../Devices/models/IDevice";
import {
  authApiHeaders,
  Configuration,
  ediApiHeaders
} from "../core/configuration/config";
import { deviceCollectionFromApi } from "../Devices/services/DeviceService";
import { globalStateReducerTypes } from "../reducers/globalStateReducer";
import { Thunk } from "../core/store";
import { createErrorConsoleMessage } from "../core/utilities/ServiceUtilities";
import { checkError, SnackbarError } from "../core/utilities/SnackbarUtilities";

export const openMenuAction = (
  currentState: boolean
): globalStateReducerTypes => {
  return { type: ActionType.OPEN_MENU_CHANGE, payload: !currentState };
};

export const updateClient = (): globalStateReducerTypes => {
  sessionStorage.removeItem("cachedIndexHTML");
  window.location.reload(true);
  return { type: ActionType.CLIENT_UPDATE };
};

export const logIn: Thunk<globalStateReducerTypes> = (query: string) => {
  return async (dispatch): Promise<ICurrentUser | SnackbarError> => {
    if (query) {
      const paramsDict = query
        .substring(1)
        .split("&")
        .map(queryParameter => queryParameter.split("="));
      const code = paramsDict.filter(
        parameterDict => parameterDict[0] === "code"
      );
      const token = paramsDict.filter(
        parameterDict => parameterDict[0] === "token"
      );
      const noHeader = paramsDict.filter(
        parameterDict => parameterDict[0] === "noheader"
      );
      if (token.length === 1) {
        const jwtToken = token[0].pop();
        history.replace(history.location.pathname + "?noheader");
        if (jwtToken) {
          localStorage.setItem("authToken", jwtToken);
        }
      }
      if (noHeader.length === 1) {
        sessionStorage.setItem("noHeader", "true");
      }
      if (code.length === 1) {
        try {
          const response: AxiosResponse<{ token: string }> = await axios.post(
            Configuration.AuthApiBaseUrl,
            null,
            {
              ...authApiHeaders,
              params: {
                code: code[0].pop(),
                client_id: Configuration.auth.clientId,
                redirect_uri: Configuration.auth.redirectUri
              }
            }
          );
          localStorage.setItem("authToken", response.data.token);
          history.replace("/");
        } catch (e) {
          createErrorConsoleMessage(e, "logIn::getToken", {
            params: {
              code: code[0].pop(),
              client_id: Configuration.auth.clientId,
              redirect_uri: Configuration.auth.redirectUri
            }
          });
          dispatch({ type: ActionType.LOGIN_ERROR, payload: e });
          return checkError(e);
        }
      }
    }
    if (sessionStorage.getItem("noHeader") === "true") {
      dispatch({ type: ActionType.NO_HEADER });
    }
    const token = localStorage.getItem("authToken");
    if (token) {
      const { influxConfig } = Configuration;
      axios.defaults.headers.common["x-lnx-token"] = token;
      influxConfig.options.headers = {
        ...influxConfig.options.headers,
        "x-lnx-token": token
      };
      Configuration.influxClient = new InfluxDB(
        Configuration.influxConfig as any
      );
      try {
        const user: ICurrentUser = JwtDecode(token);
        dispatch({ type: ActionType.AUTHENTICATION, payload: user });
        return user;
      } catch (_) {
        logOut();
      }
    } else {
      logOut();
    }
  };
};

export const logOut = (): globalStateReducerTypes => {
  localStorage.removeItem("authToken");
  window.location.assign(
    Configuration.auth.cognitoRoot +
      Configuration.auth.logout +
      "?response_type=code&client_id=" +
      Configuration.auth.clientId +
      "&redirect_uri=" +
      Configuration.auth.redirectUri
  );
  return { type: ActionType.LOGOUT };
};

export const getResources: Thunk<globalStateReducerTypes> = () => {
  return async (dispatch): Promise<IResourceLink[] | SnackbarError> => {
    try {
      const response: AxiosResponse<IDeviceCollectionApi> = await axios.get(
        Configuration.EdiAPIUrl + "/devices",
        ediApiHeaders
      );

      dispatch({
        type: ActionType.GET_CONNECTED_DEVICES,
        payload: response.data.totalItems
      });

      const resources: IResourceLink[] = deviceCollectionFromApi(response.data)
        .members.filter(device => {
          if (device.template.parameters) {
            return (
              device.template.parameters.filter(
                property => property.name.toUpperCase() === "LEVEL"
              ).length > 0
            );
          }
          return false;
        })
        .map(device => {
          return { name: device.name, id: device.shortId as string };
        });
      dispatch({ type: ActionType.GET_RESOURCES, payload: resources });
      return resources;
    } catch (err) {
      createErrorConsoleMessage(err, "getResources");
      return checkError(err);
    }
  };
};

export const toggleToolbarMenuState = (
  toolbarMenuState: Element | null
): globalStateReducerTypes => {
  return {
    type: ActionType.TOOLBAR_MENU_CHANGE_STATE,
    payload: toolbarMenuState
  };
};

export const refreshToken: Thunk<globalStateReducerTypes> = (
  activeRole?: string,
  activeSubscription?: string
) => async (dispatch, getState) => {
  const {
    globalState: { user }
  } = getState();
  const { AuthApiBaseUrl, influxConfig } = Configuration;
  if (activeRole && activeSubscription && user) {
    if (activeSubscription === user.subId && activeRole === user.roleId) {
      return;
    }
  }
  try {
    const {
      data: { token }
    }: AxiosResponse<{ token: string }> = await axios.post(
      AuthApiBaseUrl + "/refresh",
      null,
      authApiHeaders
    );
    localStorage.setItem("authToken", token);
    axios.defaults.headers.common["x-lnx-token"] = token;
    influxConfig.options.headers = {
      ...influxConfig.options.headers,
      "x-lnx-token": token
    };
    Configuration.influxClient = new InfluxDB(
      Configuration.influxConfig as any
    );
    try {
      const user: ICurrentUser = JwtDecode(token);
      dispatch({ type: ActionType.AUTHENTICATION, payload: user });
      return user;
    } catch (e) {
      dispatch({ type: ActionType.LOGIN_ERROR, payload: e });
    }
  } catch (e) {
    dispatch({ type: ActionType.LOGIN_ERROR, payload: e });
  }
};
