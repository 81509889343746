import moment from "moment";
import { Moment } from "moment";

import { ILoadable } from "../../../core/models/MixinInterfaces";

export interface IParameterUnits {
  [parameter: string]: { unitCode: string; unitText: string };
}

export interface IViewDeviceParameters extends ILoadable {
  chartOverload: boolean;
  device: string;
  from: Moment;
  to: Moment;
  showHours: boolean;
  parameter: string;
  parameterList: string[];
  panelExpanded: boolean;
  aggregationEnabled: boolean;
  aggregationInterval: string;
  aggregationIntervalList: string[];
  aggregationFunction: string[];
  aggregationFunctionList: string[];
  chart: IDeviceParameterChartItem[];
  parameterUnits: IParameterUnits;
}

export interface IDeviceParameterChartItem {
  value?: number;
  min?: number;
  max?: number;
  median?: number;
  average?: number;
  unit: string;
  time: string;
  ts?: number;
  delta?: number;
}

export const initialViewDeviceParameters: IViewDeviceParameters = {
  isLoading: true,
  device: "",
  panelExpanded: false,
  chart: [],
  chartOverload: false,
  from: moment().startOf("day"),
  to: moment()
    .startOf("day")
    .add(1, "days"),
  showHours: false,
  parameter: "",
  parameterList: [],
  aggregationEnabled: false,
  aggregationFunction: ["Max"],
  aggregationFunctionList: ["Max", "Min", "Average", "Median"],
  aggregationInterval: "",
  aggregationIntervalList: ["", "Hour", "Day", "Week", "Month", "Year"],
  parameterUnits: {}
};
