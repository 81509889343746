import * as React from "react";
import { Field, FormikProps } from "formik";
import styled from "styled-components";
import {
  Button,
  LinearProgress,
  InputLabel,
  MenuItem,
  Grid
} from "@material-ui/core";

import { IExportDevice } from "../models/IExportDevice";
import { Select, StyledSelect } from "../../components/Select";
import { IDevice } from "../../Devices/models/IDevice";
import { FormikDatePicker } from "../../components/DatePicker";

export const ExportDatepicker = styled(Field)`
  &&& {
    width: 100%;
    margin: 10px 0;
  }
`;
ExportDatepicker.displayName = "ExportDatepicker";

export const ExportDeviceForm: React.FunctionComponent<
  FormikProps<IExportDevice>
> = ({ submitForm, isSubmitting, values }: FormikProps<IExportDevice>) => {
  const { device, isLoading, from, to, deviceList } = values;
  const deviceNotSelected = device.id === ""; // TODO: change after Devices are refactored
  const submitDisabled =
    isSubmitting || isLoading || deviceNotSelected || from.isSameOrAfter(to);
  return (
    <form>
      <ExportDatepicker
        name="from"
        label="From Date (including)"
        component={FormikDatePicker}
        disabled={isSubmitting || isLoading}
      />
      <br />
      <ExportDatepicker
        name="to"
        label="To Date (including)"
        disabled={isSubmitting || isLoading}
        minDate={from.clone().add(1, "days")}
        minDateMessage="To Date should not be before or equal to From Date"
        component={FormikDatePicker}
      />
      <br />
      <StyledSelect>
        <InputLabel htmlFor="device.id">Device</InputLabel>
        <Field
          name="device.id"
          label="Device"
          component={Select}
          disabled={isSubmitting || isLoading}
        >
          {deviceList.map((exportedDevice: IDevice) => (
            <MenuItem key={exportedDevice.id} value={exportedDevice.id}>
              {`${exportedDevice.serial} - ${exportedDevice.name}`}
            </MenuItem>
          ))}
        </Field>
      </StyledSelect>
      <br />
      <StyledSelect>
        <InputLabel htmlFor="fileFormat">File Format</InputLabel>
        <Field
          name="fileFormat"
          label="Format"
          component={Select}
          disabled={isSubmitting || isLoading}
        >
          {values.formatList.map((format: string) => (
            <MenuItem key={format} value={format}>
              {format.toUpperCase()}
            </MenuItem>
          ))}
        </Field>
      </StyledSelect>
      <br />
      {(isSubmitting || isLoading) && <LinearProgress />}
      <br />
      <Grid
        container={true}
        item={true}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          color="primary"
          disabled={submitDisabled}
          onClick={submitForm}
        >
          Download
        </Button>
      </Grid>
    </form>
  );
};
ExportDeviceForm.displayName = "ExportDeviceForm";
