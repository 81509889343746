import React from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import { RootRef } from "@material-ui/core";

import { withThemeProvider } from "../core/withThemeProvider";
import { IRootState } from "../core/store";
import { getLocations } from "./actions/locationAction";
import { Configuration } from "../core/configuration/config";
import { ILocation } from "./models/IGeolocation";
import {
  FullHeightBackground,
  StyledTitle
} from "../components/sharedStyledComponents";
import { DeviceClusteringMap } from "./components/DeviceClusteringMap";
import {
  isSnackbarError,
  SnackbarError
} from "../core/utilities/SnackbarUtilities";
import { withSnackbar, WithSnackbarProps } from "notistack";

interface IDevicesStateProps {
  globalMap: ILocation;
}

interface IDevicesDispatchProps {
  getDevices: (
    height: number,
    width: number
  ) => Promise<ILocation | SnackbarError>;
}

export class Location extends React.Component<
  IDevicesStateProps & IDevicesDispatchProps & WithSnackbarProps
> {
  private container: React.RefObject<HTMLDivElement> = React.createRef();

  public async componentDidMount() {
    const { getDevices, enqueueSnackbar } = this.props;
    if (this.container.current) {
      const { clientHeight, clientWidth } = this.container.current;
      const locations = await getDevices(clientHeight, clientWidth);
      if (isSnackbarError(locations)) {
        enqueueSnackbar(locations.message, locations.options);
      }
    }
  }

  public render() {
    const { globalMap } = this.props;
    return (
      <React.Fragment>
        {Configuration.isMobile ? <StyledTitle>Locations</StyledTitle> : ""}
        {/*refactor this to style inheritance with as keyword after moving to styled components v4*/}
        <RootRef rootRef={this.container}>
          <FullHeightBackground component="div">
            {globalMap.isLoading ? (
              <GoogleMapReact
                bootstrapURLKeys={{ key: Configuration.geo.googleMapsKey }}
                center={globalMap.center}
                zoom={globalMap.zoom}
              />
            ) : (
              <DeviceClusteringMap locations={globalMap.locations} />
            )}
          </FullHeightBackground>
        </RootRef>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  globalMap: state.locations
});

const mapDispatchToProps = (dispatch: any): IDevicesDispatchProps => {
  return {
    getDevices: (height: number, width: number) =>
      dispatch(getLocations(height, width))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(Location)));
