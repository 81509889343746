import axios, { AxiosResponse } from "axios";

import {
  IDeviceAlertCollectionApi,
  IDeviceAlertCollection
} from "../models/IAlerts";
import {
  Configuration,
  earaApiHeaders,
  devicesPrefixURL
} from "../../../core/configuration/config";
import { ActionType } from "../../../core/ActionTypes";
import {
  deviceAlertCollectionFromApi,
  getDeviceOfAlert
} from "../services/DeviceAlertService";
import { Thunk } from "../../../core/store";
import { deviceAlertCollectionReducerTypes } from "../reducers/alertCollectionReducer";
import {
  createBlockableDispatch,
  createErrorConsoleMessage
} from "../../../core/utilities/ServiceUtilities";
import { getDeviceById } from "../../actions/deviceAction";
import {
  checkError,
  SnackbarError
} from "../../../core/utilities/SnackbarUtilities";

export const getAlertCollectionByDeviceId: Thunk<
  deviceAlertCollectionReducerTypes
> = (id: string) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IDeviceAlertCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_ALERT_COLLECTION_LOADING,
      payload: true
    });

    const { deviceCollection } = getState();

    if (deviceCollection.members.length > 0) {
      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: getDeviceOfAlert(id, deviceCollection)
      });
    } else {
      dispatch(getDeviceById(id));
    }

    try {
      const deviceAlertCollection: AxiosResponse<
        IDeviceAlertCollectionApi
      > = await axios.get(Configuration.EaraAPIUrl + "/alerts", {
        ...earaApiHeaders,
        params: {
          deviceId: devicesPrefixURL + id
        }
      });

      const deviceAlerts: IDeviceAlertCollection = deviceAlertCollectionFromApi(
        deviceAlertCollection.data
      );

      blockableDispatch({
        type: ActionType.GET_DEVICE_ALERT_COLLETION,
        payload: deviceAlerts
      });

      return deviceAlerts;
    } catch (err) {
      createErrorConsoleMessage(err, "getAlertCollectionByDeviceId", { id });
      return checkError(err);
    } finally {
      blockableDispatch({
        type: ActionType.DEVICE_ALERT_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};

export const changePageAlertCollectionByDeviceId: Thunk<
  deviceAlertCollectionReducerTypes
> = (id: string, page: string) => {
  return async (
    dispatch,
    getState,
    opt
  ): Promise<IDeviceAlertCollection | SnackbarError> => {
    const blockableDispatch = createBlockableDispatch(
      dispatch,
      opt.history.location.key
    );
    blockableDispatch({
      type: ActionType.DEVICE_ALERT_COLLECTION_LOADING,
      payload: true
    });

    const { deviceCollection } = getState();

    if (deviceCollection.members.length > 0) {
      blockableDispatch({
        type: ActionType.GET_DEVICE,
        payload: getDeviceOfAlert(id, deviceCollection)
      });
    } else {
      dispatch(getDeviceById(id));
    }

    try {
      const deviceAlertCollection: AxiosResponse<
        IDeviceAlertCollectionApi
      > = await axios.get(Configuration.APIBaseURL + page, {
        ...earaApiHeaders,
        params: {
          deviceId: devicesPrefixURL + id
        }
      });

      const deviceAlerts: IDeviceAlertCollection = deviceAlertCollectionFromApi(
        deviceAlertCollection.data
      );

      blockableDispatch({
        type: ActionType.GET_DEVICE_ALERT_COLLETION,
        payload: deviceAlerts
      });

      return deviceAlerts;
    } catch (err) {
      createErrorConsoleMessage(err, "changeAlertCollectionPageByDeviceId", {
        id
      });
      return checkError(err);
    } finally {
      dispatch({
        type: ActionType.DEVICE_ALERT_COLLECTION_LOADING,
        payload: false
      });
    }
  };
};
