import * as React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { NotAvailable } from "../../../components/MeasurementNA";
import { StyledProgress } from "../../Resources";
import { ILoadable } from "../../../core/models/MixinInterfaces";

interface IResourceVolumePrediction extends ILoadable {
  month: string;
  volume?: number;
  volumeUnit?: string;
}

export const ResourceVolumePredictionContainer = styled(Grid)`
  && {
    border-top: 1px dashed #e6e6e6;
    max-width: 350px;
    padding: 20px 25px;
    height: 99px;
  }
`;
ResourceVolumePredictionContainer.displayName =
  "ResourceVolumePredictionContainer";

export const ResourceVolumeValueBlock = styled.span`
  font-size: 50px;
  font-weight: lighter;
  & > span {
    font-size: 20px;
    align-self: baseline;
    padding-left: 5px;
  }
`;
ResourceVolumeValueBlock.displayName = "ResourceVolumeValueBlock";

export const ResourceVolumeDetailsBlock = styled.div`
  text-align: right;
  text-transform: uppercase;
  font-size: 14px;
  & > div {
    color: #8e8e8e;
    padding-top: 5px;
    font-size: 10px;
  }
`;
ResourceVolumeDetailsBlock.displayName = "ResourceVolumeDetailsBlock";

export const ResourceVolumePrediction: React.FunctionComponent<
  IResourceVolumePrediction
> = props => {
  const { isLoading, volume, volumeUnit, month } = props;
  return (
    <ResourceVolumePredictionContainer
      container={true}
      item={true}
      xs={12}
      direction="row"
      alignItems="center"
      justify="space-between"
    >
      <ResourceVolumeValueBlock>
        {isLoading && <StyledProgress />}
        {!isLoading && (
          <NotAvailable fontSize="30px" property={volume}>
            {volume}
            <span>{volumeUnit}</span>
          </NotAvailable>
        )}
      </ResourceVolumeValueBlock>
      {
        <ResourceVolumeDetailsBlock>
          {month}
          <div>75% chance</div>
        </ResourceVolumeDetailsBlock>
      }
    </ResourceVolumePredictionContainer>
  );
};
