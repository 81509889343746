import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

export class ScrollTop extends React.Component<RouteComponentProps> {
  public componentWillUpdate(nextProps: RouteComponentProps) {
    const {
      location,
      history: { action }
    } = nextProps;
    if (location !== this.props.location) {
      if (action === "PUSH") {
        window.scrollTo(0, 0);
      }
    }
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter(ScrollTop);
