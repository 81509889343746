import moment from "moment";
import { Moment } from "moment";
import { InfluxDB } from "influx";
import { AxiosRequestConfig } from "axios";

import { theme } from "../withThemeProvider";
const development = process.env.NODE_ENV === "development";
const preProd = process.env.REACT_APP_STAGE_NAME === "stg";
const production = !Boolean(development || preProd);

const heramesApiUrl = "https://api.herames.net";
const ellenexApiUrl = "https://api.ellenex.net";

export const devicesPrefixURL = "/v1/edi/devices/";
export const alertsPrefixURL = "/v1/eara/alerts/";

export const Configuration = {
  APIBaseURL: production ? ellenexApiUrl : heramesApiUrl,
  exportApiBaseUrl: production ? ellenexApiUrl : heramesApiUrl,
  deviceAlertsApiBaseUrl: production ? ellenexApiUrl : heramesApiUrl,
  EdiAPIUrl: production ? ellenexApiUrl + "/v1/edi" : heramesApiUrl + "/v1/edi",
  EsmaAPIBaseUrl: production ? ellenexApiUrl : heramesApiUrl,
  EsmaAPIUrl: production
    ? ellenexApiUrl + "/v1/esma"
    : heramesApiUrl + "/v1/esma",
  AuthApiBaseUrl: production
    ? ellenexApiUrl + "/v1/auth/token"
    : heramesApiUrl + "/v1/auth/token",
  EaraAPIBaseUrl: production ? ellenexApiUrl : heramesApiUrl,
  EaraAPIUrl: production
    ? ellenexApiUrl + "/v1/eara"
    : heramesApiUrl + "/v1/eara",
  EdnaAPIBaseUrl: production ? ellenexApiUrl : heramesApiUrl,
  EdnaAPIUrl: production
    ? ellenexApiUrl + "/v1/edna"
    : heramesApiUrl + "/v1/edna",
  ExportAPIUrl: production
    ? ellenexApiUrl + "/exports"
    : heramesApiUrl + "/exports",
  auth: production
    ? {
        cognitoRoot:
          "https://auth.ellenex.net/",
        login: "login",
        logout: "logout",
        redirectUri: document.location ? document.location.origin : "",
        clientId: "go7ub2sqap0m0d8b618a8it2c"
      }
    : {
        cognitoRoot:
          "https://beta-ellenex-net.auth.ap-southeast-2.amazoncognito.com/",
        login: "login",
        logout: "logout",
        redirectUri: document.location ? document.location.origin : "",
        clientId: "ibq5s4341ha3iscp5qk9v5i10"
      },
  development,
  menuWidth: 240,
  menuHoverColor: "rgba(0, 0, 0, 0.12)",
  rowsPerTablePage: 50,
  isMobile: window.innerWidth > theme.breakpoints.values.md,
  datepicker: {
    format: "DD/MM/YYYY",
    mask: "__/__/____",
    maskChar: "_"
  },
  dateTimePicker: {
    format: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    maskChar: "_"
  },
  geo: {
    googleMapsKey: "AIzaSyBXvStDcNmaOrP3peTcX9lfF0TGIv2hYDI",
    mapCenter: {
      lat: -25.61,
      lng: 134.35472
    },
    defaultZoom: {
      noDevice: 4,
      oneDevice: 15
    },
    clustering: {
      minZoom: 3,
      maxZoom: 25,
      radius: 40
    }
  },
  influxConfig: {
    protocol: "https",
    host: "api.ellenex.net",
    port: "",
    database: "v1",
    options: {
      headers: {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP",
        "x-lnx-token": ""
      },
      hostname: "api.ellenex.net/v1/influxdb"
    }
  },
  influxClient: new InfluxDB(),
  threshold: {
    battery: 3.2,
    temperature: 50,
    time: (time: Moment) => time.isSameOrBefore(moment().subtract(1, "days"))
  },
  defaultDateFormat: "DD.MM.YYYY",
  defaultTimeFormat: "HH:mm:ss",
  devices: {
    defaultMeasurementPeriod: "24h",
    defaultMeasurementParameter: "level",
    defaultMeasurementParameterUnit: "mtr",
    measurementPeriods: [
      { text: "12 Samples", value: "12" },
      { text: "24 Hours", value: "24h" },
      { text: "7 Days", value: "7d" },
      { text: "30 Days", value: "30d" },
      { text: "1 Year", value: "365d" }
    ]
  },
  viewDeviceParameterTableLength: 100,
  deviceMainMeasuredParameters: [
    "volumePercent",
    "volume",
    "level",
    "pressure",
    "turbidity",
    "temperature",
    "chargeLevel",
    "rssi",
  ],
  units: [
    { unitCode: "", unitText: "" },
    { unitCode: "MTR", unitText: "m" },
    { unitCode: "CEL", unitText: "°C" },
    { unitCode: "VLT", unitText: "V" },
    { unitCode: "BAR", unitText: "bar" },
    { unitCode: "PAL", unitText: "Pa" },
    { unitCode: "L", unitText: "L" },
    { unitCode: "MAL", unitText: "ML" },
    { unitCode: "G/ML", unitText: "g/mL" },
    { unitCode: "%", unitText: "%" }
  ],
  colors: {
    icon: {
      default: "#0000b0",
      normal: "#00b000",
      danger: "#ff0050",
      blue: "#2196f3"
    },
    charts: {
      cartesianGrid: "#e5e5e5",
      referenceLine: "#6b6b6b",
      viewDeviceParameter: {
        max: "#f58231",
        min: "#4363d8",
        mean: "#3cb44b",
        median: "#ffe119"
      },
      deviceChart: {
        min: "#2196f3",
        max: "#000000"
      },
      thresholdReferenceLine: "orange"
    },
    rainForecastChartColors: [
      "#003b71",
      "#064580",
      "#0F5699",
      "#1B69B3",
      "#1F78CC",
      "#1688F2",
      "#40a4f2",
      "#6EB9FF",
      "#79c0ff",
      "#81cbff",
      "#85d4ff",
      "#92dfff"
    ]
  },
  limits: {
    deviceParameterResultsLength: 500
  },
  clientVersionCheckIntervalMs: 10000,
  baseUnits: {
    cel: " °C",
    pal: " Pa",
    vlt: " V",
    mtr: " m",
    percent: " %"
  },
  snackbarAutohideDuration: {
    success: 4000,
    error: 15000
  },
  alert: {
    timeMultipliers: {
      m: { value: 1, name: "Minute(s)" },
      h: { value: 60, name: "Hour(s)" },
      d: { value: 60 * 24, name: "Day(s)" }
    },
    operations: [
      { id: "LESS_THAN", name: "Less than" },
      { id: "GREATER_THAN", name: "Greater than" },
      { id: "EQUAL_TO", name: "Equal to" },
      { id: "LESS_THAN_OR_EQUAL_TO", name: "Less than or equal to" },
      { id: "GREATER_THAN_OR_EQUAL_TO", name: "Greater than or equal to" },
      { id: "MISSED_DATA", name: "Missed data" }
    ],
    types: [
      { id: "WARNING", name: "Warning" },
      { id: "CRITICAL", name: "Critical" }
    ],
    notificationTypes: [
      { id: "SMS", name: "SMS" },
      { id: "EMAIL", name: "Email" }
    ]
  },
  logo: "/img/logo-white.png",
  companyName: "Ellenex"
};

Configuration.influxClient = new InfluxDB(Configuration.influxConfig as any);

export const ediApiHeaders: AxiosRequestConfig = {
  headers: production
    ? {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
      }
    : {
        "x-api-key": "6QYFxhuG7e8EASKxD6BpF2SFGv1WyEZx2i6BQGr4"
      }
};

export const esmaApiHeaders: AxiosRequestConfig = {
  headers: production
    ? {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
      }
    : {
        "x-api-key": "lHXlMHYfC39dFUqkHcBdralH7fggUNRuSGOnzZTa"
      }
};

export const authApiHeaders: AxiosRequestConfig = {
  headers: production
    ? {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
      }
    : {
        "x-api-key": "Nmgs3RYCB2zi9wLfFMvR1BU8qEtf3QiaMdVALocj"
      }
};

export const eramaApiHeaders: AxiosRequestConfig = {
  headers: production
    ? {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
      }
    : {
        "x-api-key": "CVhPZkMS538XDP8ELbGzuaEU063cMain790tk38W"
      }
};

export const earaApiHeaders: AxiosRequestConfig = {
  headers: production
    ? {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
      }
    : {
        "x-api-key": "eX7jiengip1Thie0Oogh5ag2naiSuir3eguqu2Ej"
      }
};

export const ednaApiHeaders: AxiosRequestConfig = {
  headers: production
    ? {
        "x-api-key": "UmEgY1iPtp7fRw2WLNHvd97POL5qFJAoaAjLs9SP"
      }
    : {
        "x-api-key": "Qui5oocapuX0aer4ucuJee3GOsho5fe6noo8naeN"
      }
};
