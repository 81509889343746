import * as React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { StyledDepthIcon } from "../../../components/DepthIcon";
import { StyledProgress } from "../../Resources";
import { NotAvailable } from "../../../components/MeasurementNA";
import { ILoadable } from "../../../core/models/MixinInterfaces";

interface IResourceVolumeDepthValues extends ILoadable {
  depth?: number;
  depthUnit?: string;
}

export const ResourceVolumeAdditionalData = styled(Grid)`
  && {
    padding: 15px 15px 30px;
    width: 300px;
  }
  & > div {
    display: inherit;
    align-items: center;
  }
`;
ResourceVolumeAdditionalData.displayName = "ResourceVolumeAdditionalData";

export const ResourceVolumeAdditionalDataDescription = styled(Grid)`
  font-size: 13px;
  text-transform: uppercase;
`;
ResourceVolumeAdditionalDataDescription.displayName =
  "ResourceVolumeAdditionalDataDescription";

export const ResourceVolumeAdditionalDataValue = styled.span`
  font-size: 20px;
  padding: 5px 0 0 10px;
  & > span {
    font-size: 10px;
    padding-left: 3px;
  }
`;
ResourceVolumeAdditionalDataValue.displayName =
  "ResourceVolumeAdditionalDataValue";

export const ResourceVolumeDepth: React.FunctionComponent<
  IResourceVolumeDepthValues
> = props => {
  const { isLoading, depth, depthUnit } = props;
  return (
    <ResourceVolumeAdditionalData
      container={true}
      item={true}
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <ResourceVolumeAdditionalDataDescription>
        Current Depth
      </ResourceVolumeAdditionalDataDescription>
      <div>
        <StyledDepthIcon />
        {isLoading && <StyledProgress margin="0 15px" />}
        {!isLoading && (
          <ResourceVolumeAdditionalDataValue>
            <NotAvailable property={depth}>
              {depth}
              <span>{depthUnit}</span>
            </NotAvailable>
          </ResourceVolumeAdditionalDataValue>
        )}
      </div>
    </ResourceVolumeAdditionalData>
  );
};
ResourceVolumeDepth.displayName = "ResourceVolumeDepth";
