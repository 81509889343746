import * as React from "react";
import moment from "moment";
import { FieldProps } from "formik";
import {
  KeyboardDateTimePicker,
  MaterialUiPickersDate,
  KeyboardDateTimePickerProps
} from "@material-ui/pickers";

import { Configuration } from "../core/configuration/config";

export type IDateTimePickerProps = FieldProps & KeyboardDateTimePickerProps;

export const fieldToDateTimePicker = ({
  field,
  form,
  ...props
}: IDateTimePickerProps): KeyboardDateTimePickerProps => {
  return {
    ...props,
    ...field
  };
};

export const FormikDateTimePicker: React.ComponentType<IDateTimePickerProps> = (
  props: IDateTimePickerProps
) => {
  const { name } = props.field;
  const { setFieldValue, setFieldError, errors } = props.form;
  const { format, mask, maskChar } = Configuration.dateTimePicker;
  const sfv = (date: MaterialUiPickersDate) => {
    if (date) {
      setFieldValue(name, date ? date : moment());
    }
  };
  const setError = (_: React.ReactNode, error: any) => {
    setFieldError(name, error);
  };
  const currentError = errors[name];
  return (
    <KeyboardDateTimePicker
      {...fieldToDateTimePicker(props)}
      autoOk={true}
      format={format}
      ampm={false}
      mask={mask}
      maskChar={maskChar}
      onChange={sfv}
      error={Boolean(currentError)}
      onError={setError}
      animateYearScrolling={false}
    />
  );
};
FormikDateTimePicker.displayName = "FormikDateTimePicker";
