import * as Influx from "influx";
import moment, { Moment } from "moment";

import { Configuration } from "../../../core/configuration/config";
import { limitDecimal } from "../../../core/utilities/ServiceUtilities";
import { IDeviceApi } from "../../models/IDevice";
import { IDeviceParameterChartItem } from "../models/IViewDeviceParameters";

// export const getDeviceParametersService = (device: IDeviceApi) => {
//   const deviceParameters = device.template.parameters || [];
//   let deviceParameterUnits = {};
//
//   const basicCalculationParams = deviceParameters.filter(
//     parameter =>
//       parameter.method &&
//       parameter.method.kind === "BasicCalculation" &&
//       parameter.hasOwnProperty("collection")
//   );
//
//   basicCalculationParams.forEach(({ name, unitText, unitCode }) => {
//     deviceParameterUnits[name] = { unitText, unitCode };
//   });
//
//   return {
//     parameterList: basicCalculationParams.map(({ name }) => name),
//     parameterUnits: deviceParameterUnits
//   };
// };

export const getDeviceParametersService = (device: IDeviceApi) => {
  const deviceParameters = device.template.parameters || [];
  let deviceParameterUnits = {};
  deviceParameters.forEach(parameter => {
    deviceParameterUnits[parameter.name] = {
      unitText: parameter.unitText,
      unitCode: parameter.unitCode
    };
  });
  return {
    parameterList: deviceParameters.map(parameter => parameter.name),
    parameterUnits: deviceParameterUnits
  };
};


export const getParameterChartService = async (
  deviceId: string,
  parameter: string,
  start: string,
  end: string,
  unitCode: string,
  unitText: string
): Promise<IDeviceParameterChartItem[]> => {
  const result = await Configuration.influxClient.query(`
      SELECT "${parameter.toLowerCase()}" as val, "time"
      FROM observations
      WHERE device=${Influx.escape.stringLit(deviceId)}
      AND unit = ${Influx.escape.stringLit(unitCode.toLowerCase())}
      AND time >= ${Influx.escape.stringLit(start)}
      AND time <= ${Influx.escape.stringLit(end)}
      ORDER BY time ASC
      LIMIT ${Configuration.limits.deviceParameterResultsLength}
    `);
  const chartItems: IDeviceParameterChartItem[] = [];
  result
    .map(
      (
        measurement: any,
        index: number,
        array: any[]
      ): IDeviceParameterChartItem => {
        return {
          value: limitDecimal(measurement.val, 2) as number,
          time: moment(measurement.time).format("DD/MM/Y HH:mm"),
          unit: " " + unitText,
          ts: measurement.time,
          delta:
            index > 0 ? measurement.time - array[index - 1].time : undefined
        };
      }
    )
    .forEach((item, index, array) => {
      // if (index > 1 && item.delta) {
      //   const prevDelta = array[index - 1].delta as number;
      //   if (item.delta > prevDelta * 1.25) {
      //     chartItems.push(
      //       {
      //         value: undefined,
      //         time: item.time,
      //         unit: item.unit
      //       },
      //       {
      //         value: undefined,
      //         time: item.time,
      //         unit: item.unit
      //       },
      //       {
      //         value: undefined,
      //         time: item.time,
      //         unit: item.unit
      //       }
      //     );
      //   }
      // }
      chartItems.push({ value: item.value, unit: item.unit, time: item.time });
    });

  return chartItems
};

export const getParameterAggregatedChartService = async (
  deviceId: string,
  parameter: string,
  start: string,
  end: string,
  aggregationFunction: string[],
  aggregationInterval: string,
  unitCode: string,
  unitText: string
): Promise<IDeviceParameterChartItem[]> => {
  let interval = "";
  let format = "";
  switch (aggregationInterval) {
    case "Hour":
      interval = "1h";
      format = "MMM D LT";
      break;
    case "Day":
      interval = "1d";
      format = "MMM D";
      break;
    case "Week":
      interval = "7d";
      format = "MMM D";
      break;
    case "Month":
      interval = "30d";
      format = "MMM Y";
      break;
    case "Year":
      interval = "365d";
      format = "Y";
      break;
    default:
      throw new Error("Unsupported range");
  }
  const functions = aggregationFunction
    .map(
      fn =>
        (fn === "Average" ? "MEAN" : fn.toUpperCase()) +
        '("' +
        parameter.toLowerCase() +
        '")'
    )
    .join(", ");
  const result = await Configuration.influxClient.query(`
      SELECT ${functions}
      FROM observations
      WHERE device=${Influx.escape.stringLit(deviceId)}
      AND unit = ${Influx.escape.stringLit(unitCode.toLowerCase())}
      AND time >= ${Influx.escape.stringLit(start)}
      AND time <= ${Influx.escape.stringLit(end)}
      GROUP BY time(${interval})
      ORDER BY time ASC
      LIMIT ${Configuration.limits.deviceParameterResultsLength}
    `);
  return result.map((measurement: any) => {
    return {
      max: limitDecimal(measurement.max as number, 2),
      min: limitDecimal(measurement.min as number, 2),
      median: limitDecimal(measurement.median as number, 2),
      average: limitDecimal(measurement.mean as number, 2),
      time: moment(measurement.time).format(format),
      unit: " " + unitText
    };
  });
};

export const convertToHours = (ts: Moment, showHours: boolean): string =>
  showHours ? ts.toISOString() : ts.startOf("day").toISOString();
