import * as React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { IResource } from "../../models/IResource";
import { ResourceVolumeValues } from "./ResourceVolumeValues";
import { ResourceVolumeDepth } from "./ResourceVolumeDepth";
import { ResourceVolumePrediction } from "./ResourceVolumePrediction";

interface IResourceVolumeProps {
  data: IResource;
}

export const ResourceVolumeBaseData = styled(Grid)`
  && {
    padding: 30px 0;
  }
`;
ResourceVolumeBaseData.displayName = "ResourceVolumeBaseData";
export const ResourceVolume: React.FunctionComponent<
  IResourceVolumeProps
> = props => {
  const { data } = props;
  return (
    <Grid
      direction={"column"}
      container={true}
      item={true}
      justify={"space-around"}
    >
      <ResourceVolumeBaseData
        container={true}
        item={true}
        direction="row"
        justify="center"
      >
        <ResourceVolumeValues
          isLoading={data.isLoading}
          volume={data.volume}
          volumeUnit={data.volumeUnit}
          volumeLastYear={data.volumeLastYear}
          volumeLastYearUnit={data.volumeLastYearUnit}
        />
        <ResourceVolumeDepth
          isLoading={data.isLoading}
          depth={data.depth}
          depthUnit={data.depthUnit}
        />
      </ResourceVolumeBaseData>
      <Grid container={true} item={true} direction="row" justify="center">
        <ResourceVolumePrediction
          isLoading={data.isLoading}
          month={data.nextMonthName}
          volume={data.nextMonthVolumeChance75}
          volumeUnit={data.nextMonthVolumeChance75Unit}
        />
        <ResourceVolumePrediction
          isLoading={data.isLoading}
          month={data.nextThreeMonthName}
          volume={data.nextThreeMonthVolumeChance75}
          volumeUnit={data.nextThreeMonthVolumeChance75Unit}
        />
      </Grid>
    </Grid>
  );
};
ResourceVolume.displayName = "ResourceVolume";
