import React, { ChangeEvent } from "react";
import ReactExpr, { ReactExprProps } from "react-expr";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel
} from "@material-ui/core";
import styled from "styled-components";
import { Field, FieldProps, getIn } from "formik";

import { expressionConfig } from "../core/configuration/expressionConfig";
import { IParameters } from "../Devices/models/IDevice";

export const StyledReactExpr = styled(ReactExpr)<IReactExpressionMaterialProps>`
  &&& {
    resize: none;
    height: auto;
    padding: 0;
    background: none;
    width: 100%;
    opacity: ${props => (props.disabled ? 0.65 : 1)};
  }
`;

// any types as material ui isn't deisgned to work with contenteditable div
export const ReactExprCustom = (props: any) => {
  const { inputRef, onChange, expressionDoc, ...other } = props;
  const changeHandler = (expression: string) => {
    onChange({ target: { value: expression } });
  };
  const setReference = (ref: any) => {
    if (ref && other) {
      // explicitly connecting props and handlers as materialUI and formik aren't working with contenteditable div
      ref.ref.current.name = other.name;
      ref.ref.current.onfocus = other.onFocus;
      ref.ref.current.onblur = other.onBlur;
    }
    inputRef(ref ? ref.ref.current : null);
  };
  return (
    <StyledReactExpr
      {...other}
      innerRef={setReference}
      onChange={changeHandler}
      doc={expressionDoc}
    />
  );
};

interface IReactExpressionMaterialProps {
  parameters?: IParameters[];
  label?: string;
  disabled?: boolean;
}
type typeExpressionField = FieldProps &
  ReactExprProps &
  IReactExpressionMaterialProps;

export const ExpressionField: React.ComponentType<typeExpressionField> = ({
  field,
  form,
  ...props
}) => {
  const { name, value } = field;
  const { setFieldValue, errors, touched } = form;
  const { parameters, label, disabled } = props;
  const errorList = getIn(errors, name);
  const touchedList = getIn(touched, name);
  const hasError = Boolean(touchedList) && Boolean(errorList);
  const changeExpression = (
    expression: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFieldValue(name, expression.target.value);
  };
  const expressionDoc = { ...expressionConfig };
  if (parameters) {
    // removing cached values
    expressionDoc.variables = { ...expressionConfig.variables };

    // and setting known device parameters
    parameters.forEach(parameter => {
      expressionDoc.variables[parameter.name] = {
        kind: "int"
      };
    });
  }
  return (
    <FormControl {...props} {...field}>
      <InputLabel error={hasError} htmlFor={field.name}>
        {label}
      </InputLabel>
      <Input
        {...field}
        value={value}
        disabled={disabled}
        readOnly={disabled}
        onChange={changeExpression}
        id={field.name}
        multiline={true}
        error={hasError}
        inputComponent={ReactExprCustom}
        inputProps={{
          expressionDoc
        }}
      />
      {hasError && <FormHelperText error={true}>{errorList}</FormHelperText>}
    </FormControl>
  );
};
ExpressionField.displayName = "FormikMaterialUIExpressionField";

export const StyledExpressionField = styled(Field)`
  &&& {
    width: 100%;
    margin-bottom: 20px;
    & > * {
      width: 100%;
    }
  }
`;
