import React from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from "@material-ui/core";

import { ITableDeleteCell } from "../../../core/models/IShared";

export const StyledLinearProgress = styled(LinearProgress)`
  && {
    margin: 15px;
  }
`;
StyledLinearProgress.displayName = "StyledLinearProgress";

export const StyledDialogTitle = styled(DialogTitle)`
  && > h6 {
    font-size: 1rem;
  }
`;
StyledDialogTitle.displayName = "StyledDialogTitle";

interface IDeviceAlertDeleteDialogProps {
  deviceAlertInfo: ITableDeleteCell;
  handleDeleteDialogClose: (deviceAlertInfo: ITableDeleteCell) => void;
  handleDeleteDeviceAlert: (deviceAlertInfo: ITableDeleteCell) => void;
  isLoading: boolean;
}

export const DeviceAlertDeleteDialog: React.FunctionComponent<
  IDeviceAlertDeleteDialogProps
> = ({
  deviceAlertInfo,
  handleDeleteDialogClose,
  handleDeleteDeviceAlert,
  isLoading
}: IDeviceAlertDeleteDialogProps) => {
  const deleteDialogClose = () => {
    handleDeleteDialogClose(deviceAlertInfo);
  };

  const deleteDeviceAlert = () => {
    handleDeleteDeviceAlert(deviceAlertInfo);
  };

  return (
    <Dialog
      open={deviceAlertInfo.visible}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle id="alert-dialog-title">
        Are you sure you want to delete this alert?
      </StyledDialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {deviceAlertInfo.title}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={deleteDialogClose}
          color="primary"
          variant="contained"
          disabled={isLoading}
          autoFocus
        >
          No
        </Button>
        <Button
          onClick={deleteDeviceAlert}
          color="primary"
          disabled={isLoading}
        >
          Yes
        </Button>
      </DialogActions>

      {isLoading && <StyledLinearProgress />}
    </Dialog>
  );
};
