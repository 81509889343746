import * as React from "react";
import styled from "styled-components";
import { Paper, Typography, Divider, Grid } from "@material-ui/core";

export const StretchedGrid = styled(Grid)<ITileProps>`
  ${props => (props.stretch ? "display: flex; flex:1;width:100%" : "")};
  padding: ${props => (props.padding ? props.padding : "auto")};
`;
StretchedGrid.displayName = "StretchedGrid";

export const ResourceGridTitle = styled(Typography)`
  text-align: center;
  padding: 10px 0;
  text-transform: uppercase;
`;
ResourceGridTitle.displayName = "ResourceGridTitle";

export const StretchedPaper = styled(Paper)`
  width: 100%;
`;
StretchedPaper.displayName = "StretchedPaper";

interface ITileProps {
  name: string;
  stretch?: number;
  padding?: string;
}

export const ResourceGridTile: React.FunctionComponent<ITileProps> = props => {
  const { children, name } = props;
  return (
    <StretchedGrid item={true} container={true} {...props}>
      <StretchedPaper>
        <Grid container={true} item={true} direction={"column"} xs={12}>
          <ResourceGridTitle variant="subtitle1">{name}</ResourceGridTitle>
          <Divider />
        </Grid>
        <Grid container={true} item={true} xs={true} alignItems="stretch">
          {children}
        </Grid>
      </StretchedPaper>
    </StretchedGrid>
  );
};
ResourceGridTile.displayName = "ResourceGridTile";
