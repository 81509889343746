import { IDeviceModelNetwork } from "./IDeviceModelNetwork";
import { IInfluxTableQuery } from "../../Resources/models/IResource";
import { Configuration } from "../../core/configuration/config";
import {
  IApiResponseHeader,
  ICollection,
  IDetermination,
  ILoadable
} from "../../core/models/MixinInterfaces";
import {DevicePrimaryParameter} from "../../Location/models/IGeolocation"

export interface IGeolocation {
  latitude?: string;
  longitude?: string;
}

export interface IAddress {
  addressCountry?: string;
  addressLocality?: string;
  addressRegion?: string;
  postalCode?: string;
  streetAddress?: string;
}

export interface IPosition {
  address?: IAddress;
  geo: IGeolocation;
}

export interface IParameterMethod {
  "@type": string;
  expression: string;
  kind: string;
  value?: string;
}

export interface IDeviceTemplate {
  parameters?: IParameters[];
}

export interface IParameters {
  "@type": string;
  visible?: boolean;
  dataType: string;
  featureOf: string;
  method?: IParameterMethod;
  name: string;
  text?: string;
  unitCode: string;
  unitText: string;
  collection?: string;
}

export const initialParameter: IParameters = {
  "@type": "",
  visible: false,
  dataType: "",
  featureOf: "",
  method: {
    "@type": "",
    expression: "",
    kind: "",
    value: ""
  },
  name: "",
  text: "",
  unitCode: "",
  unitText: ""
};

export interface IWeather {
  bom?: {
    forecastArea: string;
  };
}

export interface IDeviceApi extends IApiResponseHeader {
  serial: string;
  name: string;
  note?: string;
  owner?: string;
  subscription: string;
  deviceModel: string;
  networks?: IDeviceModelNetwork[];
  manualLocation?: IPosition;
  autoLocation?: IPosition;
  template: IDeviceTemplate;
  weather: IWeather;
}

export interface IDeviceCollectionApi extends IApiResponseHeader, ICollection {
  "@context": string;
  member: IDeviceApi[];
  page?: number;
}

export interface IDeviceChartMeasurement {
  value?: number;
  minValue?: number;
  maxValue?: number;
  time: string;
  unit: string;
  property: string;
}

export interface IDeviceChartMeasurementQuery {
  deviceId: string;
  period: string;
  property: string;
  unit: string;
  unitCode: string;
}

export interface IDeviceMeasurement extends ILoadable {
  values?: IInfluxTableQuery;
  primaryParameter?: DevicePrimaryParameter;
}

export interface IChartQueryResult {
  device: string;
  chartData: IDeviceChartMeasurement[];
}

export interface IChartAlert {
  warning: number[];
  critical: number[];
}

export interface IDomain {
  maxThreshold: number;
  minThreshold: number;
}

export interface IDeviceChart extends ILoadable {
  values?: IDeviceChartMeasurement[];
  thresholds: IChartAlert;
  domain: IDomain;
}

export interface IDevice extends ILoadable, IDetermination {
  serial: string;
  name: string;
  note: string;
  measurements: IDeviceMeasurement;
  model?: string;
  owner?: string;
  networks?: IDeviceModelNetwork[];
  subscription: string;
  deviceModel: string;
  manualLocation?: IPosition;
  autoLocation?: IPosition;
  measurementsChart: IDeviceChart;
  template: IDeviceTemplate;
  weather: IWeather;
  payloadFieldProperties: IParameters[];
  basicCalculationProperties: IParameters[];
  valueParameters: IParameters[];
}

export const initialDevice: IDevice = {
  isLoading: true,
  id: "",
  type: "",
  measurements: {
    isLoading: true
  },
  measurementsChart: {
    isLoading: true,
    thresholds: { warning: [], critical: [] },
    domain: { maxThreshold: 0, minThreshold: 0 }
  },
  manualLocation: {
    geo: {
      longitude: "",
      latitude: ""
    }
  },
  autoLocation: {
    geo: {
      longitude: "",
      latitude: ""
    }
  },
  name: "",
  deviceModel: "",
  model: "",
  note: "",
  serial: "",
  template: {},
  weather: {},
  payloadFieldProperties: [],
  basicCalculationProperties: [],
  valueParameters: [],
  subscription: ""
};

export interface IDeviceCollection
  extends ILoadable,
    IDetermination,
    ICollection {
  context?: string;
  members: IDevice[];
  totalItems: number;
  measurementPeriod: string;
}

export const initialDeviceCollection: IDeviceCollection = {
  isLoading: true,
  id: "",
  type: "",
  measurementPeriod: Configuration.devices.defaultMeasurementPeriod,
  members: [],
  totalItems: 0,
  view: {
    "@id": "",
    "@type": "",
    first: "",
    last: "",
    next: "",
    previous: ""
  }
};
