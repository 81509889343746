import React from "react";
import { LinearProgress, Tooltip } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import { StyledBatteryIcon } from "../../../components/ThermometerIcon";
import { Configuration } from "../../../core/configuration/config";

export const BatteryCell: React.FunctionComponent<IIconCell> = props => {
  const { isLoading, values } = props.measurements;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          {values && values.chargeLevel && values.chargeLevel.value && (
            <Tooltip
              title={`Battery: ${values.chargeLevel.value} ${values.chargeLevel.unitText}`}
            >
              <span>
                <StyledBatteryIcon
                  danger={
                    values.chargeLevel &&
                    values.chargeLevel.value < Configuration.threshold.battery
                      ? 1
                      : 0
                  }
                />
              </span>
            </Tooltip>
          )}
        </React.Fragment>
      )}
    </CustomizedTableCell>
  );
};
