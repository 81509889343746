import * as React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

import { StyledVolumeIcon } from "../../../components/VolumeIcon";
import { NotAvailable } from "../../../components/MeasurementNA";
import { growthPercent } from "../../services/ResourceService";
import { StyledProgress } from "../../Resources";
import { ILoadable } from "../../../core/models/MixinInterfaces";

interface IResourceVolumeValues extends ILoadable {
  volume?: number;
  volumeUnit?: string;
  volumeLastYear?: number;
  volumeLastYearUnit?: string;
}

export const ResourceVolumeValuesGrid = styled(Grid)`
  && {
    padding-bottom: 40px;
  }
`;
ResourceVolumeValuesGrid.displayName = "ResourceVolumeValuesGrid";

export const ResourceVolumeValuesIcon = styled(StyledVolumeIcon)`
  height: 100px;
`;
ResourceVolumeValuesIcon.displayName = "ResourceVolumeValuesIcon";

export const ResourceVolumeValuesMeasurement = styled.div`
  color: black;
  padding-left: 20px;
  font-size: 68px;
  font-weight: lighter;
  align-items: baseline;
  & > span {
    padding-left: 10px;
    font-size: 27px;
  }
`;
ResourceVolumeValuesMeasurement.displayName = "ResourceVolumeValuesMeasurement";

export const ResourceVolumeValuesCaption = styled.div`
  font-size: 12px;
  color: #8e8e8e;
  text-align: center;
`;
ResourceVolumeValuesCaption.displayName = "ResourceVolumeValuesCaption";

export const ResourceVolumeValues: React.FunctionComponent<
  IResourceVolumeValues
> = props => {
  const {
    volume,
    volumeUnit,
    volumeLastYear,
    volumeLastYearUnit,
    isLoading
  } = props;
  const changes = growthPercent(volume, volumeLastYear);
  return (
    <ResourceVolumeValuesGrid
      container={true}
      item={true}
      direction="row"
      alignItems="center"
      justify="center"
    >
      <ResourceVolumeValuesIcon />
      <ResourceVolumeValuesCaption>
        {isLoading && <StyledProgress margin="0 20px" />}
        {!isLoading && (
          <ResourceVolumeValuesMeasurement>
            <NotAvailable fontSize="40px" property={volume}>
              {volume}
              <span>{volumeUnit}</span>
              {changes && (
                <span>
                  {changes.percent}% Last Year(
                  {volumeLastYear}
                  {volumeLastYearUnit})
                </span>
              )}
            </NotAvailable>
          </ResourceVolumeValuesMeasurement>
        )}
      </ResourceVolumeValuesCaption>
    </ResourceVolumeValuesGrid>
  );
};
ResourceVolumeValues.displayName = "ResourceVolumeValues";
