import moment from "moment";
import * as Influx from "influx";
import { IResults } from "influx";

import {
  IChartQueryResult,
  IDevice,
  IDeviceChartMeasurementQuery,
  IWeather
} from "../../Devices/models/IDevice";
import {
  IForecast,
  IInfluxTableQuery,
  IRainChance,
  IResourceDepthData,
  IResourceVolumeData,
  IVolume,
  IVolumeChanges
} from "../models/IResource";
import { Configuration } from "../../core/configuration/config";
import {
  getDeviceUnitTextByParameterName,
  getFormattedIdList,
  getTablePropertyMeasurement,
  getUnitTextByUnitCode,
  IInfluxMeasuredProperties
} from "./ResourceService";
import { createErrorConsoleMessage } from "../../core/utilities/ServiceUtilities";
import { getDevicePrimaryParameter } from "../../Devices/services/DeviceService";

export const getTableMeasurements = async (
  devices: IDevice[]
): Promise<IInfluxTableQuery[]> => {
  if (devices.length === 0) {
    return [];
  }
  const filteredDeviceList = devices.filter(device => Boolean(device.shortId));
  const result: IResults<
    IInfluxMeasuredProperties[] & any
  > = await Configuration.influxClient.query(`
      SELECT 
      ${Configuration.deviceMainMeasuredParameters
        .map(parameter => `LAST(${parameter.toLowerCase()}) as ${parameter}`)
        .join(", ")}
      FROM observations
      WHERE device = ${getFormattedIdList(filteredDeviceList)}
      GROUP BY device, unit
    `);
  const latestTime: IResults<any> = await Configuration.influxClient.query(`
      SELECT * 
      FROM observations 
      WHERE device = ${getFormattedIdList(filteredDeviceList)} 
      GROUP BY device
      ORDER BY time DESC 
      LIMIT 1
      `);
  const deviceList = {};
  filteredDeviceList.forEach(device => {
    deviceList[device.shortId as string] = {
      device: device.shortId
    };
  });
  result.forEach(resultMeasurement => {
    const filteredDevice = devices
      .filter(device => device.shortId === resultMeasurement.device)
      .pop();
    if (filteredDevice) {
      Configuration.deviceMainMeasuredParameters.forEach(parameter => {
        if (resultMeasurement[parameter]) {
          deviceList[resultMeasurement.device][
            parameter
          ] = getTablePropertyMeasurement(
            filteredDevice,
            parameter,
            resultMeasurement[parameter],
            resultMeasurement.unit
          );
        }
      });
    }
  });
  latestTime.forEach(timeMeasurement => {
    if (timeMeasurement.time) {
      deviceList[timeMeasurement.device].lastSeen = moment(
        timeMeasurement.time
      );
    }
  });
  const measurementsArray: IInfluxTableQuery[] = [];
  Object.keys(deviceList).forEach(key => {
    const filteredDevice = devices
      .filter(device => device.shortId === deviceList[key].device)
      .pop();
    if (filteredDevice) {
      const primaryParameter = getDevicePrimaryParameter(
        filteredDevice.template,
        true
      );
      if (primaryParameter) {
        Object.keys(deviceList[key]).forEach(measurementKey => {
          if (
            measurementKey.toLowerCase() === primaryParameter.name.toLowerCase()
          ) {
            deviceList[key].primaryParameter = deviceList[key][measurementKey];
          }
        });
      }
    }
    measurementsArray.push(deviceList[key]);
  });
  return measurementsArray;
};

export const getTableChartData = async ({
  deviceId,
  period,
  property,
  unit,
  unitCode
}: IDeviceChartMeasurementQuery): Promise<IChartQueryResult[]> => {
  if (!period || !property) {
    return [];
  }
  if (period === "12") {
    const result: IResults<
      IInfluxMeasuredProperties[] & any
    > = await Configuration.influxClient.query(`
        SELECT ${property.toLowerCase()}, unit, time
        FROM observations
        WHERE device = ${Influx.escape.stringLit(deviceId)}
        AND unit = ${Influx.escape.stringLit(unitCode.toLowerCase())}
        GROUP BY device
        ORDER BY time DESC
        limit 12
      `);
    return result.groups().map(deviceMeasurement => {
      return {
        device: deviceMeasurement.tags.device,
        chartData: deviceMeasurement.rows
          .map(chartMeasurement => {
            return {
              value: chartMeasurement[property.toLowerCase()],
              time: moment(chartMeasurement.time).format("LT"),
              unit: unit,
              property
            };
          })
          .filter(
            measurement =>
              typeof measurement.value === "number" && measurement.time
          )
          .reverse()
      };
    });
  } else {
    let interval = "";
    let offset = "";
    let limit = 0;
    let format = "";
    switch (period) {
      case "24h":
        interval = "1h";
        offset = "1h";
        limit = 24;
        format = "LT";
        break;
      case "7d":
        interval = "1d";
        offset = "1d";
        limit = 8;
        format = "MMM D";
        break;
      case "30d":
        interval = "1d";
        offset = "1d";
        limit = 31;
        format = "MMM D";
        break;
      case "365d":
        interval = "30d";
        offset = "30d";
        limit = 13;
        format = "MMM Y";
        break;
      default:
        throw new Error("Unsupported range");
    }
    const result: IResults<
      IInfluxMeasuredProperties[] & any
    > = await Configuration.influxClient.query(`
        SELECT MIN(${property.toLowerCase()}), MAX(${property.toLowerCase()}), time
        FROM observations
        WHERE device = ${Influx.escape.stringLit(
          deviceId
        )} AND time <= now() + 1d
        AND time >= now() - ${period}
        AND unit = ${Influx.escape.stringLit(unitCode.toLowerCase())}
        GROUP BY device, unit, time(${interval},${offset})
        ORDER BY time ASC
        LIMIT ${limit}
      `);
    return result.groups().map(deviceMeasurement => {
      return {
        device: deviceMeasurement.tags.device,
        chartData: deviceMeasurement.rows
          .map(chartMeasurement => {
            return {
              minValue: chartMeasurement.min,
              maxValue: chartMeasurement.max,
              time: moment(chartMeasurement.time).format(format),
              unit: unit,
              property
            };
          })
          .filter(
            measurement =>
              typeof measurement.minValue === "number" ||
              typeof measurement.maxValue === "number"
          )
      };
    });
  }
};

export const getResourceVolumeData = async (
  deviceId: string,
  device?: IDevice
): Promise<IResourceVolumeData | Error> => {
  try {
    const { unitCode, unitText } = getDeviceUnitTextByParameterName(
      "volume",
      device
    );
    let result;
    if (unitCode) {
      result = await Configuration.influxClient.query<IResourceVolumeData>(`
        SELECT LAST(volume) as volume
          FROM observations 
          WHERE device=${Influx.escape.stringLit(deviceId)}
          AND unit=${Influx.escape.stringLit(unitCode.toLowerCase())};
        `);
    }
    if (!result || result.length !== 1) {
      result = await Configuration.influxClient.query<IResourceVolumeData>(`
        SELECT LAST(volume) as volume
          FROM observations 
          WHERE device=${Influx.escape.stringLit(deviceId)};
        `);
    }
    return result.map(
      (measurement): IResourceVolumeData => {
        return {
          volume: measurement.volume,
          volumeUnit: unitText
        };
      }
    )[0];
  } catch (e) {
    createErrorConsoleMessage(e, "getResourceVolumeData", { deviceId });
    return e;
  }
};

export const getResourceDepthData = async (
  deviceId: string,
  device?: IDevice
): Promise<IResourceDepthData | Error> => {
  try {
    const { unitText, unitCode } = getDeviceUnitTextByParameterName(
      "level",
      device
    );
    let result;
    if (unitCode) {
      result = await Configuration.influxClient.query<IResourceDepthData>(`
        SELECT LAST(level) as depth
          FROM observations 
          WHERE device=${Influx.escape.stringLit(deviceId)}
          AND unit=${Influx.escape.stringLit(unitCode.toLowerCase())};
        `);
    }
    if (!result || result.length !== 1) {
      result = await Configuration.influxClient.query<IResourceDepthData>(`
          SELECT LAST(level) as depth
          FROM observations 
          WHERE device=${Influx.escape.stringLit(deviceId)};
        `);
    }
    return result.map(
      (measurement: any): IResourceDepthData => {
        return {
          depth: measurement.depth,
          depthUnit: unitText
        };
      }
    )[0];
  } catch (e) {
    createErrorConsoleMessage(e, "getResourceDepthData", { deviceId });
    return e;
  }
};

export const getResourceMedianDataByPeriod = async (
  deviceId: string,
  start: string,
  end: string,
  device?: IDevice
): Promise<IVolume | Error> => {
  try {
    const { unitText } = getDeviceUnitTextByParameterName("volume", device);
    const result: IResults<any> = await Configuration.influxClient.query(`
          SELECT MEDIAN(volume)
          FROM observations
          WHERE device=${Influx.escape.stringLit(deviceId)} 
          AND time >= ${Influx.escape.stringLit(start)} 
          AND time <= ${Influx.escape.stringLit(end)}
          GROUP BY unit
        `);
    return result.map(
      (measurement): IVolume => {
        return {
          value: measurement.median,
          time: moment(measurement.time),
          unit: unitText
        };
      }
    )[0];
  } catch (e) {
    createErrorConsoleMessage(e, "getResourceMedianDataByPeriod", {
      deviceId,
      start,
      end
    });
    return e;
  }
};

export const getResourceVolumeChanges = async (
  deviceId: string,
  start: string,
  end: string,
  device?: IDevice
): Promise<IVolumeChanges[] | Error> => {
  try {
    const { unitText, unitCode } = getDeviceUnitTextByParameterName(
      "volume",
      device
    );
    let result;
    if (unitCode) {
      result = await Configuration.influxClient.query<IVolumeChanges>(`
          SELECT MAX(volume), MIN(volume)
          FROM observations
          WHERE device=${Influx.escape.stringLit(deviceId)} 
          AND time >= ${Influx.escape.stringLit(start)} 
          AND time <= ${Influx.escape.stringLit(end)}
          AND unit=${Influx.escape.stringLit(unitCode.toLowerCase())} 
          GROUP BY time(30d)
        `);
    }
    if (!result) {
      result = await Configuration.influxClient.query<IVolumeChanges>(`
          SELECT MAX(volume), MIN(volume)
          FROM observations
          WHERE device=${Influx.escape.stringLit(deviceId)} 
          AND time >= ${Influx.escape.stringLit(start)} 
          AND time <= ${Influx.escape.stringLit(end)}
          GROUP BY time(30d)
        `);
    }
    return result
      .map(
        (measurement): IVolumeChanges => {
          return {
            max: measurement.max,
            min: measurement.min,
            prediction: undefined,
            time: moment(measurement.time),
            unit: unitText
          };
        }
      )
      .filter(
        formattedMeasurement =>
          formattedMeasurement.time.isSameOrAfter(moment(start)) &&
          formattedMeasurement.time.isSameOrBefore(moment(end))
      );
  } catch (e) {
    createErrorConsoleMessage(e, "getResourceVolumeChanges", {
      deviceId,
      start,
      end
    });
    return e;
  }
};

export const getResourceVolumeChangesForecast = async (
  deviceId: string,
  start: string,
  end: string,
  device?: IDevice
): Promise<IVolumeChanges[] | Error> => {
  try {
    const { unitText, unitCode } = getDeviceUnitTextByParameterName(
      "volume",
      device
    );
    let result;
    if (unitCode) {
      result = await Configuration.influxClient.query<IVolumeChanges>(`
          SELECT MAX(volume)
          FROM forecasts_v1
          WHERE device=${Influx.escape.stringLit(deviceId)}
          AND time >= ${Influx.escape.stringLit(start)} 
          AND time <= ${Influx.escape.stringLit(end)} 
          AND rainfallChance='75'
          AND unit=${Influx.escape.stringLit(unitCode.toLowerCase())} 
          GROUP BY time(30d)
        `);
    }
    if (!result) {
      result = await Configuration.influxClient.query<IVolumeChanges>(`
          SELECT MAX(volume)
          FROM forecasts_v1
          WHERE device=${Influx.escape.stringLit(deviceId)}
          AND time >= ${Influx.escape.stringLit(start)} 
          AND time <= ${Influx.escape.stringLit(end)} 
          AND rainfallChance='75'
          GROUP BY time(30d)
        `);
    }
    return result
      .map(
        (measurement): IVolumeChanges => {
          return {
            min: undefined,
            max: undefined,
            prediction: measurement.max,
            time: moment(measurement.time),
            unit: unitText
          };
        }
      )
      .filter(
        formattedMeasurement =>
          formattedMeasurement.time.isSameOrAfter(moment(start)) &&
          formattedMeasurement.time.isSameOrBefore(moment(end))
      );
  } catch (e) {
    createErrorConsoleMessage(e, "getResourceVolumeChangesForecast", {
      deviceId,
      start,
      end
    });
    return e;
  }
};

export const getLastForecastsForPeriod = async (
  deviceId: string,
  duration: string,
  start: string,
  end?: string,
  device?: IDevice
): Promise<IForecast[] | undefined | Error> => {
  try {
    const { unitText } = getDeviceUnitTextByParameterName("volume", device);
    const result: IResults<any> = await Configuration.influxClient.query(`
          SELECT LAST(volume)
          FROM forecasts_v1
          WHERE device=${Influx.escape.stringLit(deviceId)}
          AND time >= ${Influx.escape.stringLit(start)} 
          ${end ? "AND time <= " + Influx.escape.stringLit(end) : ""}
          GROUP BY rainfallChance, unit
          ORDER BY time DESC
        `);
    if (result.length === 0) {
      return;
    }
    return result.map(
      (forecast): IForecast => {
        return {
          value: forecast.last,
          chance: forecast.rainfallChance,
          unit: unitText,
          time: moment(forecast.time)
        };
      }
    );
  } catch (e) {
    createErrorConsoleMessage(e, "getLastForecastsForPeriod", {
      deviceId,
      duration,
      start,
      end
    });
    return e;
  }
};

export const getRainChanceForPeriod = async (
  deviceId: string,
  start: string,
  weather?: IWeather,
  end?: string
): Promise<IRainChance[] | undefined | Error> => {
  try {
    if (!weather || !weather.bom || !weather.bom.forecastArea) {
      return;
    }
    const result: IResults<any> = await Configuration.influxClient.query(`
          SELECT MEDIAN(chance)
          FROM forecasts_v1
          WHERE location=${Influx.escape.stringLit(weather.bom.forecastArea)} 
          AND time >= ${Influx.escape.stringLit(start)} 
          ${end ? "AND time <= " + Influx.escape.stringLit(end) : ""}
          GROUP BY atLeastMm, unit
        `);
    if (result.length === 0) {
      return;
    }
    return result
      .map(
        (rainForecast): IRainChance => {
          return {
            chance: rainForecast.median,
            atLeastMm: parseInt(rainForecast.atLeastMm, 10),
            unit: getUnitTextByUnitCode(rainForecast.unit),
            time: moment(rainForecast.time)
          };
        }
      )
      .sort((forecastA, forecastB) => {
        if (forecastA.atLeastMm < forecastB.atLeastMm) {
          return -1;
        }
        if (forecastA.atLeastMm > forecastB.atLeastMm) {
          return 1;
        }
        return 0;
      });
  } catch (e) {
    createErrorConsoleMessage(e, "getRainChanceForPeriod", {
      deviceId,
      start,
      weather,
      end
    });
    return e;
  }
};
