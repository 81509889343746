import * as React from "react";
import styled from "styled-components";
import { theme } from "../core/withThemeProvider";

export const VolumeIcon: React.FunctionComponent = props => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      style={{
        stroke: "none",
        strokeWidth: 1,
        fill: "none",
        fillRule: "evenodd"
      }}
    >
      <circle
        style={{
          stroke: "currentColor",
          strokeWidth: 0.936170213
        }}
        cx="12"
        cy="12"
        r="11"
      />
      <path
        d="M20.9499812,9.14468085 C21.2476137,10.0583697 21.4085106,11.0337994 21.4085106,12.0468085 C21.4085106,17.2171338 17.2171338,21.4085106 12.0468085,21.4085106 C6.87648319,21.4085106 2.68510638,17.2171338 2.68510638,12.0468085 C2.68510638,11.0337994 2.84600329,10.0583697 3.14363582,9.14468085 M20.9499812,9.14468085 C20.9499812,9.14468085 20.9499812,9.14468085 20.9499812,9.14468085 L3.14363582,9.14468085"
        style={{
          fill: "currentColor"
        }}
      />
    </g>
  </svg>
);
VolumeIcon.displayName = "VolumeIcon";

export const StyledVolumeIcon = styled(VolumeIcon)`
  color: ${theme.palette.primary.main};
  width: auto;
  height: 100%;
`;
StyledVolumeIcon.displayName = "StyledVolumeIcon";
