import * as React from "react";
import moment from "moment";
import { FieldProps } from "formik";
import {
  KeyboardDatePicker,
  MaterialUiPickersDate,
  KeyboardDatePickerProps
} from "@material-ui/pickers";

import { Configuration } from "../core/configuration/config";

export type IDatePickerProps = FieldProps & KeyboardDatePickerProps;

export const fieldToDatepicker = ({
  field,
  form,
  ...props
}: IDatePickerProps): KeyboardDatePickerProps => {
  return {
    ...props,
    ...field
  };
};

export const FormikDatePicker: React.ComponentType<IDatePickerProps> = (
  props: IDatePickerProps
) => {
  const { name } = props.field;
  const { setFieldValue, setFieldError, errors } = props.form;
  const { format, mask, maskChar } = Configuration.datepicker;
  const sfv = (date: MaterialUiPickersDate) => {
    date && setFieldValue(name, date ? date : moment());
  };
  const setError = (_: React.ReactNode, error: any) => {
    setFieldError(name, error);
  };
  const currentError = errors[name];
  return (
    <KeyboardDatePicker
      {...fieldToDatepicker(props)}
      autoOk={true}
      format={format}
      mask={mask}
      maskChar={maskChar}
      onChange={sfv}
      error={Boolean(currentError)}
      onError={setError}
      animateYearScrolling={false}
    />
  );
};
FormikDatePicker.displayName = "FormikDatePicker";
