import * as React from "react";
import { RouteComponentProps } from "react-router";
import { NavLink, withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  Drawer,
  ListItemIcon,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ListItem,
  ListItemText,
  Hidden
} from "@material-ui/core";
import {
  Dns as DeviceIcon,
  Person as PersonIcon,
  DataUsage as ResourceIcon,
  GetApp as DownloadIcon,
  MyLocation as LocationIcon,
  Dashboard as DashboardIcon
} from "@material-ui/icons";

import { Configuration } from "../core/configuration/config";
import { theme } from "../core/withThemeProvider";
import { StyledToolbarIndent } from "./sharedStyledComponents";
import { IResourceLink } from "../models/IGlobalState";

interface IDisabled {
  disabled?: boolean;
}
export const MenuLink = styled(NavLink)<IDisabled>`
  pointer-events: ${props => (props.disabled ? "none" : "all")};
  text-decoration: none;
  color: ${theme.palette.text.primary};
  &&.active {
    background-color: ${Configuration.menuHoverColor};
    display: inherit;
  }
`;
MenuLink.displayName = "MenuLink";

export const AccordionMenuLink = styled(MenuLink)`
  &&& {
    color: ${theme.typography.subtitle1.color};
    font-size: 0.9em;
    text-transform: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    padding: 5px 0;
  }
  &&.active {
    background-color: inherit;
    color: ${theme.palette.primary.main};
  }
`;

export const MenuToolbar = styled(StyledToolbarIndent)`
  && {
    width: ${Configuration.menuWidth}px;
    margin-bottom: ${theme.spacing()}px;
  }
`;
MenuToolbar.displayName = "MenuToolbar";

export const MenuDrawer = styled(Drawer)`
  && {
    z-index: ${theme.zIndex.appBar - 1};
  }
  && > * {
    :first-child {
      background: none;
    }
    :last-child {
      padding-bottom: 35px;
      width: ${Configuration.menuWidth}px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;
MenuDrawer.displayName = "MenuDrawer";

export const MenuAccordion = styled(ExpansionPanel)`
  &&::before {
    background-color: transparent;
    box-shadow: transparent;
  }
  && {
    background: none;
    box-shadow: none;
    margin: 0;
  }
`;

export const MenuAccordionTitle = styled(ExpansionPanelSummary)`
  &&& {
    padding: 0;
    min-height: inherit;
  }
  &&& > * {
    margin: 0;
  }
`;

export const MenuAccordionContent = styled(ExpansionPanelDetails)`
  && {
    padding: 0 0 10px 90px;
    display: block;
  }
`;

interface IProps {
  resources: IResourceLink[];
  resourcesLoaded: boolean;
  menuOpen: boolean;
  closeMenu: () => void;
  userId: string;
  disabled: boolean;
}

export const MenuComponent: React.FunctionComponent<
  RouteComponentProps & IProps
> = ({
  menuOpen,
  closeMenu,
  disabled,
  resources,
  resourcesLoaded,
  userId
}: RouteComponentProps & IProps) => (
  <React.Fragment>
    <Hidden smDown={true}>
      <MenuDrawer variant="persistent" open={menuOpen} onClose={closeMenu}>
        <MenuToolbar />
        <MenuLink
          activeClassName="active"
          to="/"
          disabled={disabled}
          exact={true}
        >
          <ListItem disabled={disabled} button={true}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </MenuLink>
        <MenuLink
          activeClassName="active"
          to="/devices"
          disabled={disabled || !resourcesLoaded}
        >
          <ListItem disabled={disabled || !resourcesLoaded} button={true}>
            <ListItemIcon>
              <DeviceIcon />
            </ListItemIcon>
            <ListItemText primary="Devices" />
          </ListItem>
        </MenuLink>
        <MenuAccordion disabled={!resourcesLoaded || disabled}>
          <MenuAccordionTitle>
            <ListItem disabled={disabled || !resourcesLoaded} button={true}>
              <ListItemIcon>
                <ResourceIcon />
              </ListItemIcon>
              <ListItemText primary="Resources" />
            </ListItem>
          </MenuAccordionTitle>
          <MenuAccordionContent>
            {resources.map((resource, index) => (
              <AccordionMenuLink
                key={index}
                activeClassName="active"
                disabled={disabled || !resourcesLoaded}
                to={"/resources/" + resource.id}
              >
                {resource.name}
              </AccordionMenuLink>
            ))}
          </MenuAccordionContent>
        </MenuAccordion>
        <MenuLink
          activeClassName="active"
          disabled={disabled || !resourcesLoaded}
          to="/locations"
        >
          <ListItem disabled={disabled || !resourcesLoaded} button={true}>
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText primary="Locations" />
          </ListItem>
        </MenuLink>
        <MenuLink
          activeClassName="active"
          to={"/users/" + userId}
          disabled={disabled}
        >
          <ListItem disabled={disabled} button={true}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItem>
        </MenuLink>
        <MenuLink
          activeClassName="active"
          to="/export"
          disabled={disabled || !resourcesLoaded}
        >
          <ListItem disabled={disabled || !resourcesLoaded} button={true}>
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText primary="Export" />
          </ListItem>
        </MenuLink>
      </MenuDrawer>
    </Hidden>
    <Hidden mdUp={true}>
      <MenuDrawer variant={"temporary"} open={menuOpen} onClose={closeMenu}>
        <MenuToolbar />
        <MenuLink
          activeClassName="active"
          to="/"
          disabled={disabled}
          exact={true}
        >
          <ListItem onClick={closeMenu} disabled={disabled} button={true}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </MenuLink>
        <MenuLink
          activeClassName="active"
          disabled={disabled || !resourcesLoaded}
          to="/devices"
        >
          <ListItem onClick={closeMenu} disabled={disabled || !resourcesLoaded} button={true}>
            <ListItemIcon>
              <DeviceIcon />
            </ListItemIcon>
            <ListItemText primary="Devices" />
          </ListItem>
        </MenuLink>
        <MenuAccordion disabled={disabled || !resourcesLoaded}>
          <MenuAccordionTitle>
            <ListItem disabled={disabled || !resourcesLoaded} button={true}>
              <ListItemIcon>
                <ResourceIcon />
              </ListItemIcon>
              <ListItemText primary="Resources" />
            </ListItem>
          </MenuAccordionTitle>
          <MenuAccordionContent>
            {resources.map((resource, index) => (
              <AccordionMenuLink
                key={index}
                onClick={closeMenu}
                activeClassName="active"
                disabled={disabled}
                to={"/resources/" + resource.id}
              >
                {resource.name}
              </AccordionMenuLink>
            ))}
          </MenuAccordionContent>
        </MenuAccordion>
        <MenuLink
          activeClassName="active"
          disabled={disabled || !resourcesLoaded}
          to="/locations"
        >
          <ListItem
            onClick={closeMenu}
            disabled={disabled || !resourcesLoaded}
            button={true}
          >
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText primary="Locations" />
          </ListItem>
        </MenuLink>
        <MenuLink
          activeClassName="active"
          disabled={disabled}
          to={"/users/" + userId}
        >
          <ListItem onClick={closeMenu} disabled={disabled} button={true}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="User Profile" />
          </ListItem>
        </MenuLink>
        <MenuLink
          activeClassName="active"
          disabled={disabled || !resourcesLoaded}
          to="/export"
        >
          <ListItem
            onClick={closeMenu}
            disabled={disabled || !resourcesLoaded}
            button={true}
          >
            <ListItemIcon>
              <DownloadIcon />
            </ListItemIcon>
            <ListItemText primary="Export" />
          </ListItem>
        </MenuLink>
      </MenuDrawer>
    </Hidden>
  </React.Fragment>
);

MenuComponent.displayName = "MenuComponent";
export const Menu = withRouter(MenuComponent);
Menu.displayName = "Menu";
