import * as React from "react";
import styled from "styled-components";

interface INotAvailable {
  property: any;
  fontSize?: string;
}
export const NotAvailable: React.FunctionComponent<INotAvailable> = props => {
  const { property, children } = props;
  return (property || property === 0) ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <StyledNAText {...props}>No data</StyledNAText>
  );
};
NotAvailable.displayName = "NotAvailable";

export const StyledNAText = styled.i<INotAvailable>`
  && {
    ${props => (props.fontSize ? "font-size: " + props.fontSize : null)};
    font-style: normal;
    font-weight: lighter;
    align-self: center;
    justify-self: center;
    display: flex;
  }
`;
StyledNAText.displayName = "StyledNAText";
