import * as React from "react";
import styled from "styled-components";
import { Table, TableBody, TableCell } from "@material-ui/core";

import { IDevice, IDeviceMeasurement, IParameters } from "../models/IDevice";
import { DeviceTableFooter } from "./DeviceTable/DeviceTableFooter";
import { DeviceTableRow } from "./DeviceTable/DeviceTableRow";
import { DeviceTableHead } from "./DeviceTable/DeviceTableHead";
import { IPaginationView } from "../../core/models/MixinInterfaces";

interface ICustomizedTableCell {
  textalign?: "left" | "center" | "right";
  type?: string;
  header?: 1 | 0;
  hide?: 1 | 0;
  width?: string;
  verticalalign?: string;
  paddingtop?: string;
}

export interface IIconCell {
  measurements: IDeviceMeasurement;
  parameters?: IParameters[];
}

export const CustomizedTableCell = styled(TableCell)<ICustomizedTableCell>`
  && {
    ${props => (props.hide ? "display: none" : "")};
    text-align: ${props => props.textalign || "left"};
    padding: 4px 6px 4px 14px;
    ${props =>
      props.type && props.type === "button" ? "max-width: 150px" : ""};
    ${props => (props.width ? "width: " + props.width : "")};
    ${props =>
      props.header
        ? "text-transform: uppercase; font-size: 14px; color: black; font-weight: normal;"
        : ""};
    ${props =>
      props.verticalalign ? "vertical-align: " + props.verticalalign : ""};
    ${props => (props.paddingtop ? "padding-top: " + props.paddingtop : "")};
  }
  && > i {
    display: inline;
  }
`;
CustomizedTableCell.displayName = "CustomizedTableCell";

export const DeviceTableWrapper = styled(Table)`
  min-width: 1000px;
  table-layout: fixed;
`;
DeviceTableWrapper.displayName = "DeviceTableWrapper";

export interface IDeviceTableProps {
  pagination?: IPaginationView;
  isLoading?: boolean;
  rows: IDevice[];
  totalItems?: number;
  onChangePage?: (page: string) => void;
}

export const DeviceTable: React.FunctionComponent<IDeviceTableProps> = (
  props: IDeviceTableProps
) => {
  const isDeviceCollection = Boolean(
    typeof props.totalItems === "number" &&
      props.onChangePage
  );
  return (
    <DeviceTableWrapper>
      <DeviceTableHead hide={!isDeviceCollection} isLoading={props.isLoading} />
      <TableBody>
        {props.rows.map(row => (
          <DeviceTableRow
            key={row.id}
            device={row}
            hide={!isDeviceCollection}
          />
        ))}
      </TableBody>
      <DeviceTableFooter {...props} />
    </DeviceTableWrapper>
  );
};
DeviceTable.displayName = "DeviceTable";
