import React from "react";
import { TableCell, TableFooter, TableRow } from "@material-ui/core";

import { IAlertTableProps } from "../AlertTable";
import { Pagination } from "../../../../components/Pagination";

export const DeviceAlertTableFooter: React.FunctionComponent<
  IAlertTableProps
> = ({ totalItems, onChangePage, pagination }: IAlertTableProps) => {
  return (
    <TableFooter>
      {pagination && (
        <TableRow>
          <TableCell>
            <Pagination
              totalItems={totalItems}
              onChangePage={onChangePage}
              pagination={pagination}
            />
          </TableCell>
        </TableRow>
      )}
    </TableFooter>
  );
};
