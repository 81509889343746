import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Formik, FormikActions, FormikProps } from "formik";
import { Grid, Button } from "@material-ui/core";
import { withSnackbar, WithSnackbarProps } from "notistack";

import { IRootState } from "../../core/store";
import { withThemeProvider } from "../../core/withThemeProvider";
import {
  NoStyleLink,
  StyledTitle,
  StyledTitleButtonGrid
} from "../../components/sharedStyledComponents";
import {
  clearAlert,
  createAlertByDeviceId,
  getAlertByDeviceId,
  updateAlertByDeviceId,
  deviceAlertNotificationKind,
  sendDeviceAlertTestMessage
} from "./actions/alertAction";
import { IDeviceAlert } from "./models/IAlerts";
import { EditDeviceAlertForm } from "./components/EditDeviceAlertForm";
import { StyledListIcon } from "../EditDevice";
import { IDevice } from "../models/IDevice";
import {
  getStyledSnackbarOptions,
  isSnackbarError,
  SnackbarError,
  snackbarMessages
} from "../../core/utilities/SnackbarUtilities";

interface IPathParamsType {
  id: string;
  alertId: string;
}

interface IDevicesStateProps {
  deviceAlert: IDeviceAlert;
  device: IDevice;
}

interface IDevicesDispatchProps {
  createAlert: (
    id: string,
    alert: IDeviceAlert
  ) => Promise<IDeviceAlert | SnackbarError>;
  getAlert: (
    create: boolean,
    deviceId: string,
    alertId?: string
  ) => Promise<IDeviceAlert | SnackbarError | undefined>;
  updateAlert: (
    id: string,
    alert: IDeviceAlert
  ) => Promise<IDeviceAlert | SnackbarError>;
  clearAlert: () => void;
  deviceAlertNotificationKind: (itemIndex: number) => void;
  sendDeviceAlertTestMessage: (
    phone: string
  ) => Promise<SnackbarError | undefined>;
}

type EditDeviceAlertProps = IDevicesStateProps &
  IDevicesDispatchProps &
  RouteComponentProps<IPathParamsType> &
  WithSnackbarProps;

export class EditDeviceAlert extends React.Component<EditDeviceAlertProps> {
  public async componentDidMount() {
    const {
      getAlert,
      match: {
        params: { id, alertId }
      },
      enqueueSnackbar
    } = this.props;

    const deviceAlertResult = await getAlert(
      Boolean(alertId === "create"),
      id,
      alertId
    );
    if (isSnackbarError(deviceAlertResult)) {
      enqueueSnackbar(deviceAlertResult.message, deviceAlertResult.options);
    }
  }

  public componentWillUnmount() {
    this.props.clearAlert();
  }

  public onSubmit = async (
    values: IDeviceAlert,
    { setSubmitting }: FormikActions<IDeviceAlert>
  ) => {
    const {
      updateAlert,
      createAlert,
      match: {
        params: { id, alertId }
      },
      history,
      enqueueSnackbar
    } = this.props;
    if (values) {
      const result =
        alertId === "create"
          ? await createAlert(id, values)
          : await updateAlert(alertId, values);

      if (isSnackbarError(result)) {
        enqueueSnackbar(result.message, result.options);
      } else {
        enqueueSnackbar(
          alertId === "create"
            ? snackbarMessages.DEVICE_ALERT_CREATE_SUCCESS
            : snackbarMessages.DEVICE_ALERT_UPDATE_SUCCESS,
          getStyledSnackbarOptions("success")
        );

        if (alertId === "create") {
          history.goBack();
        }
      }

      setSubmitting(false);
    }
  };

  public deviceAlertNotificationKind = (itemIndex: number) => {
    const { deviceAlertNotificationKind } = this.props;
    deviceAlertNotificationKind(itemIndex);
  };

  public sendDeviceAlertTestMessage = (phone: string) => {
    const { sendDeviceAlertTestMessage } = this.props;
    sendDeviceAlertTestMessage(phone);
  };

  public renderEditDeviceAlertForm = (
    formikProps: FormikProps<IDeviceAlert>
  ) => {
    const { device } = this.props;
    return (
      <EditDeviceAlertForm
        {...formikProps}
        device={device}
        deviceAlertNotificationKind={this.deviceAlertNotificationKind}
        sendDeviceAlertTestMessage={this.sendDeviceAlertTestMessage}
      />
    );
  };

  public generateTitle(name: string, serial: string) {
    const {
      match: {
        params: { alertId }
      }
    } = this.props;
    if (alertId === "create") {
      return "Create Device Alert";
    } else {
      if (name && serial) {
        return `Edit Device Alert for ${name} <${serial}>`;
      } else {
        return "Edit Device Alert for ...";
      }
    }
  }

  public render() {
    const {
      deviceAlert,
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Grid container={true} item={true} xs={12} md={9} lg={6} xl={6}>
        <StyledTitle
          container={true}
          alignItems="center"
          justify="space-between"
        >
          <StyledTitleButtonGrid
            container={true}
            item={true}
            justify="flex-end"
            alignItems="center"
          >
            <StyledTitleButtonGrid item={true}>
              {this.generateTitle(deviceAlert.device, deviceAlert.deviceSerial)}
            </StyledTitleButtonGrid>
            <NoStyleLink to={`/devices/${id}/alerts`}>
              <Button color="default">
                <StyledListIcon />
                Alert List
              </Button>
            </NoStyleLink>
          </StyledTitleButtonGrid>
        </StyledTitle>
        <Grid item={true} xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={deviceAlert}
            onSubmit={this.onSubmit}
            render={this.renderEditDeviceAlertForm}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state: IRootState): IDevicesStateProps => ({
  deviceAlert: state.deviceAlert,
  device: state.device
});

const mapDispatchToProps = (dispatch: any): IDevicesDispatchProps => {
  return {
    createAlert: (
      id: string,
      alert: IDeviceAlert
    ): Promise<IDeviceAlert | SnackbarError> => {
      return dispatch(createAlertByDeviceId(id, alert));
    },
    getAlert: (
      create: boolean,
      deviceId: string,
      alertId?: string
    ): Promise<IDeviceAlert | SnackbarError | undefined> => {
      return dispatch(getAlertByDeviceId(create, deviceId, alertId));
    },
    updateAlert: (
      id: string,
      alert: IDeviceAlert
    ): Promise<IDeviceAlert | SnackbarError> => {
      return dispatch(updateAlertByDeviceId(id, alert));
    },
    clearAlert: () => {
      dispatch(clearAlert());
    },
    deviceAlertNotificationKind: (itemIndex: number) => {
      dispatch(deviceAlertNotificationKind(itemIndex));
    },
    sendDeviceAlertTestMessage: (
      phone: string
    ): Promise<SnackbarError | undefined> => {
      return dispatch(sendDeviceAlertTestMessage(phone));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withThemeProvider(withSnackbar(EditDeviceAlert)));
