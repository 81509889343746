import * as React from "react";
import { LinearProgress } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import { StyledThermometerIcon } from "../../../components/ThermometerIcon";
import { Configuration } from "../../../core/configuration/config";
import { parameterExists } from "../../services/DeviceService";
import { limitDecimal } from "../../../core/utilities/ServiceUtilities";

export const TemperatureCell: React.FunctionComponent<IIconCell> = props => {
  const {
    measurements: { isLoading, values },
    parameters
  } = props;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          {values &&
            values.temperature &&
            parameterExists("Temperature", parameters) && (
              <React.Fragment>
                <StyledThermometerIcon
                  danger={
                    values.temperature &&
                    values.temperature.value >
                      Configuration.threshold.temperature
                      ? 1
                      : 0
                  }
                />
                <br />
                {values.temperature &&
                  `${limitDecimal(values.temperature.value, 1)} ${
                    values.temperature.unitText
                  }`}
              </React.Fragment>
            )}
        </React.Fragment>
      )}
    </CustomizedTableCell>
  );
};
