import React from "react";
import { LinearProgress, Tooltip } from "@material-ui/core";

import { CustomizedTableCell, IIconCell } from "../DeviceTable";
import {
  StyledRSSIErrorIcon,
  StyledRSSIHighSignalIcon,
  StyledRSSILowSignalIcon,
  StyledRSSIMidSignalIcon,
  StyledRSSINoSignalIcon
} from "../../../components/RSSIIcon";

export const RSSICell: React.FunctionComponent<IIconCell> = props => {
  const { isLoading, values } = props.measurements;
  const rssi = values && values.rssi ? values.rssi.value : null;
  const rssiUnit = values && values.rssi ? values.rssi.unitText : null;
  return (
    <CustomizedTableCell textalign="center">
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Tooltip title={`RSSI: ${rssi} ${rssiUnit}`}>
          <span>
            {rssi && rssi < 99 && rssi > 31 && <StyledRSSIErrorIcon />}
            {rssi && rssi <= 31 && rssi >= 15 && <StyledRSSIHighSignalIcon />}
            {rssi && rssi < 15 && rssi >= 10 && <StyledRSSIMidSignalIcon />}
            {rssi && rssi < 10 && <StyledRSSILowSignalIcon />}
            {rssi && rssi === 99 && <StyledRSSINoSignalIcon />}
          </span>
        </Tooltip>
      )}
    </CustomizedTableCell>
  );
};
